import { useQuery } from '@tanstack/react-query';
import { AutoComplete, AutoCompleteProps } from 'antd';
import debounce from 'lodash-es/debounce';
import isObject from 'lodash-es/isObject';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { useCallback, useMemo, useState } from 'react';

const DEBOUNCE_TIMEOUT = 1000;

export type SearchProps = {
  value?: Record<string, any> | string;
  labelName?: string;
  valueName?: string;
  queryFN: (query: string) => Promise<any[]>;
} & Omit<AutoCompleteProps, 'value'>;

export const Search = ({
  value,
  labelName = 'companyName',
  valueName = 'companyName',
  queryFN,
  onChange,
  ...rest
}: SearchProps) => {
  const [query, setQuery] = useState('');

  const calculateValue = useMemo(() => {
    return isObject(value) && valueName in value ? value[valueName] : value;
  }, [value]);

  const { data } = useQuery({
    queryKey: ['search', query],
    queryFn: (): Promise<DefaultOptionType[]> => (query.length ? queryFN(query) : Promise.resolve([])),
    select: (options) =>
      options.length
        ? options.map((info) => ({ label: info[labelName], value: info[valueName], data: info }))
        : [{ label: 'Empty Response', value: '', disabled: true }],
  });

  const options = useMemo(() => {
    return data;
  }, [data]);

  const onSearch = useCallback(
    debounce((query: string) => {
      setQuery(query);
    }, DEBOUNCE_TIMEOUT),
    [],
  );

  const handleChange = (value: any, option: DefaultOptionType | DefaultOptionType[]) => {
    if (isObject(option) && 'data' in option) {
      onChange?.(option.data, option);
    } else {
      onChange?.(value, option);
    }
  };

  return (
    <AutoComplete {...rest} value={calculateValue} options={options} onSearch={onSearch} onChange={handleChange} />
  );
};
