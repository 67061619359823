import { Button, Flex, Modal, Typography } from 'antd';

type Props = {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  labelItem: string;
  idItem: number;
};

const { Title, Paragraph } = Typography;

export const DeleteModal = ({ isOpen, onCancel, onConfirm, labelItem, idItem }: Props) => {
  return (
    <Modal width={600} footer={null} closeIcon={false} open={isOpen} onCancel={onCancel}>
      <div className="px-12 pb-6">
        <Title className="rubik-semi-bold text-center mt-3" level={3}>
          You are about to delete <br /> the {labelItem} #{idItem}
        </Title>
        <Paragraph className="text-center mt-8 pb-10">
          This action is irreversible, but you can add the other {labelItem} by clicking Add shipment location button
        </Paragraph>
        <Flex gap="large" vertical>
          <Button key="delete" type="primary" danger onClick={onConfirm}>
            Yes, I delete
          </Button>
          <Button key="cancel" onClick={onCancel}>
            Cancel
          </Button>
        </Flex>
      </div>
    </Modal>
  );
};
