/**************************************************************************************************
 *
 * copyright: HAPTIK GmbH (c) 2023-2024.  All rights reserved.
 *
 *
 *   #     #    #    ######  #######  #  #   #
 *   #     #   # #   #     #          #  #  #
 *         #  #   #        #    #     #  # #
 *   #######       # ######     #     #  ##
 *   #     # ####### #          #     #  #  #
 *   #     # #     # #          #     #  #   #
 *   #     # #     # #          #     #  #    #
 *
 *************************************************************************************************/

/**
 * A component for selecting transport modes, document types, and negotiability to create a new document.
 *
 * @since 1.0
 * @author sc
 */

import { Flex, Button, theme } from 'antd';
import { useEffect, useState } from 'react';
import { AiOutlineFileText, AiOutlineCar, AiOutlineShop, AiOutlineCheckCircle, AiOutlineCloseCircle, AiOutlineCloud } from 'react-icons/ai';
import styled from 'styled-components';

import { useBreadcrumbs } from '../../components/BreadcrumbsContext';
import { baseColors } from '../../theme';
import {useNavigate} from "react-router-dom";

const { useToken } = theme;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${baseColors.white};
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 20px;
  box-shadow: ${baseColors.boxShadowPrimary};
`;

const FilterContainer = styled(Flex)`
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
  justify-content: center;
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
`;

const SectionContainer = styled.div`
  margin-bottom: 40px;
  width: 100%;
`;

const SelectableButton = styled(Button)<{ selected: boolean }>`
  background-color: ${baseColors.white};
  color: ${baseColors.primary};
  border: 2px solid ${({ selected }) => (selected ? baseColors.primary : baseColors.border)};
  padding: 20px 40px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  margin: 10px;
  &:hover {
    border-color: ${baseColors.primaryActive};
    color: ${baseColors.primary};
  }
  svg {
    margin-right: 8px;
  }
`;

const CenteredButton = styled(Button)`
  background-color: ${baseColors.primary};
  color: ${baseColors.white};
  border: none;
  padding: 10px 30px;
  font-size: 18px;
  border-radius: 8px;
  &:hover {
    background-color: ${baseColors.primaryActive};
    color: ${baseColors.white};
  }
  display: block;
  margin: 20px auto 0;
  width: fit-content;
`;

export default function DocumentTypesPage() {
    const { updateBreadcrumbs, updateTitleText } = useBreadcrumbs();
    const token = useToken();
    const [transportMode, setTransportMode] = useState<string | null>(null);
    const [documentType, setDocumentType] = useState<string | null>(null);
    const [isNegotiable, setIsNegotiable] = useState<boolean | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        updateBreadcrumbs('Dashboard / Choose your Document');
        updateTitleText('Document Types');
    }, [updateBreadcrumbs, updateTitleText]);

    const handleTransportModeSelect = (mode: string) => {
        setTransportMode(mode);
        setDocumentType(null);
        setIsNegotiable(null);
    };

    const handleDocumentTypeSelect = (type: string) => {
        setDocumentType(type);
    };

    const handleNegotiableSelect = (negotiable: boolean) => {
        setIsNegotiable(negotiable);
    };

    const handleCreate = () => {
        if (transportMode && documentType) {
            let path = '';
            if (transportMode === 'sea') {
                if (documentType === 'houseBL') {
                    path = '/bills-of-lading/create';
                } else if (documentType === 'masterBL') {
                    path = '/bills-of-lading/create';
                } else if (documentType === 'seawaybill') {
                    path = '/bills-of-lading/create';
                }
            } else if (transportMode === 'truck') {
                path = '/truck/cmr';
            } else if (transportMode === 'air') {
                path = '/air/airwaybill';
            }

            if (path) {
                navigate(path);
            }
        }
    };
    const renderDocumentOptions = () => {
        if (transportMode === 'sea') {
            return (
                <>
                    <SelectableButton
                        selected={documentType === 'houseBL'}
                        onClick={() => handleDocumentTypeSelect('houseBL')}
                    >
                        <AiOutlineFileText /> House eBL
                    </SelectableButton>
                    <SelectableButton
                        selected={documentType === 'masterBL'}
                        onClick={() => handleDocumentTypeSelect('masterBL')}
                        disabled
                    >
                        <AiOutlineFileText /> Master eBL
                    </SelectableButton>
                    <SelectableButton
                        selected={documentType === 'seawaybill'}
                        onClick={() => handleDocumentTypeSelect('seawaybill')}
                        disabled
                    >
                        <AiOutlineFileText /> Seaway Bill
                    </SelectableButton>
                </>
            );
        } else if (transportMode === 'road') {
            return (
                <SelectableButton
                    selected={documentType === 'cmr'}
                    onClick={() => handleDocumentTypeSelect('cmr')}
                >
                    <AiOutlineFileText /> eCMR
                </SelectableButton>
            );
        } else if (transportMode === 'air') {
            return (
                <SelectableButton
                    selected={documentType === 'airwaybill'}
                    onClick={() => handleDocumentTypeSelect('airwaybill')}
                >
                    <AiOutlineFileText /> Airway Bill
                </SelectableButton>
            );
        }
        return null;
    };

    return (
        <StyledContainer>
            <SectionTitle>Choose Your Transport Mode</SectionTitle>
            <SectionContainer>
                <FilterContainer>
                    <SelectableButton
                        selected={transportMode === 'sea'}
                        onClick={() => handleTransportModeSelect('sea')}
                    >
                        <AiOutlineShop /> Sea Freight
                    </SelectableButton>
                    <SelectableButton
                        selected={transportMode === 'road'}
                        onClick={() => handleTransportModeSelect('road')}
                        disabled
                    >
                        <AiOutlineCar /> Road Freight
                    </SelectableButton>
                    <SelectableButton
                        selected={transportMode === 'air'}
                        onClick={() => handleTransportModeSelect('air')}
                        disabled
                    >
                        <AiOutlineCloud /> Air Freight
                    </SelectableButton>
                </FilterContainer>
            </SectionContainer>

            {transportMode && (
                <>
                    <SectionTitle>Choose Your Document Type</SectionTitle>
                    <SectionContainer>
                        <FilterContainer>
                            {renderDocumentOptions()}
                        </FilterContainer>
                    </SectionContainer>

                    {transportMode === 'sea' && documentType !== 'seawaybill' && (
                        <>
                            <SectionTitle>Negotiability</SectionTitle>
                            <SectionContainer>
                                <FilterContainer>
                                    <SelectableButton
                                        selected={isNegotiable === true}
                                        onClick={() => handleNegotiableSelect(true)}
                                        disabled
                                    >
                                        <AiOutlineCheckCircle /> Negotiable
                                    </SelectableButton>
                                    <SelectableButton
                                        selected={isNegotiable === false}
                                        onClick={() => handleNegotiableSelect(false)}
                                    >
                                        <AiOutlineCloseCircle /> Non-Negotiable
                                    </SelectableButton>
                                </FilterContainer>
                            </SectionContainer>
                        </>
                    )}
                </>
            )}

            <CenteredButton
                type="primary"
                onClick={handleCreate}
                disabled={documentType === null || (transportMode === 'sea' && documentType !== 'seawaybill' && isNegotiable === null)}
            >
                Create {documentType}
            </CenteredButton>
        </StyledContainer>
    );
}
