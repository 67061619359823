import { AxiosInstance } from 'axios';
import UserService from 'services/UserService';
import {
  DeleteResultDTO,
  ErrorHandler,
  GetResultDTO,
  ListResultDTO,
  PostResultDTO,
  PutResultDTO,
  UpdateCallback,
} from './hapibase';

export type AddressPostalDto = {
  
  ownerId: string;
  id?: string;
  name: string;
  tags?: string[];
  street: string;
  streetNumber: string;
  floor: string;
  additionalInfo: string;
  postalCode: string;
  city: string;
  state: string;
  country: string;
  email: string;
  phoneNumber: string;
  faxNumber: string;
  legalForm: string;
  created?: string;
  updated?:string;
};

export type AdrPostalGetResultDTO = GetResultDTO<AddressPostalDto>;
export type AdrPostalListResultDTO = ListResultDTO<AddressPostalDto>;
export type AdrPostalPutResultDTO = PutResultDTO<AddressPostalDto>;
export type AdrPostalPostResultDTO = PostResultDTO<AddressPostalDto>;
export type AdrPostalDeleteResultDTO = DeleteResultDTO<AddressPostalDto>;

/**
 *
 *
 * @param apiClient
 * @param uoId
 * @param errorHandler
 * @returns
 */
const list = async (
    apiClient: AxiosInstance,
    contactId: string,
    suspressError: boolean = true,
    errorHandler?: ErrorHandler<any>,
): Promise<AdrPostalListResultDTO> => {
  try {
    const response = await apiClient.get<AdrPostalListResultDTO>(
        '/api/companies/v1/' + UserService.getCompanyId() + '/entity/' + contactId + '/addresses/postal',
    );
    return response.data;
  } catch (error: any) {
    if (errorHandler) {
      errorHandler(error);
    }
    if (suspressError) {
      return <AdrPostalListResultDTO>{};
    }
    throw error;
  }
};


/**
 *
 *
 * @param apiClient
 * @param uoId
 * @param errorHandler
 * @returns
 */
const listEx = async (
  apiClient: AxiosInstance,
  companyId : string,
  contactId: string,
  suspressError: boolean = true,
  errorHandler?: ErrorHandler<any>,
): Promise<AdrPostalListResultDTO> => {
try {
  const response = await apiClient.get<AdrPostalListResultDTO>(
      '/api/companies/v1/' + companyId + '/entity/' + contactId + '/addresses/postal',
  );
  return response.data;
} catch (error: any) {
  if (errorHandler) {
    errorHandler(error);
  }
  if (suspressError) {
    return <AdrPostalListResultDTO>{};
  }
  throw error;
}
};

/**
 *
 * @param apiClient
 * @param id
 * @param errorHandler
 * @returns
 */
const remove = async (
    apiClient: AxiosInstance,
    contactId: string,
    id: string,
    suspressError: boolean = true,
    errorHandler?: ErrorHandler<any>,
): Promise<AdrPostalDeleteResultDTO> => {
  try {
    const response = await apiClient.delete<AdrPostalDeleteResultDTO>(
        '/api/companies/v1/' + UserService.getCompanyId() + '/entity/' + contactId + '/addresses/postal/' + id,
    );
    return response.data;
  } catch (error: any) {
    if (errorHandler) {
      errorHandler(error);
    }
    if (suspressError) {
      return <AdrPostalDeleteResultDTO>{};
    }
    throw error;
  }
};

const createNew = async (
    apiClient: AxiosInstance,
    contactId: string,
    newEntry: AddressPostalDto,
    suspressError: boolean = true,
    errorHandler?: ErrorHandler<any>,
): Promise<AdrPostalPostResultDTO> => {
  try {
    const response = await apiClient.post<AdrPostalPostResultDTO>(
        '/api/companies/v1/' + UserService.getCompanyId() + '/entity/' + contactId + '/addresses/postal/',
        newEntry,
    );
    return response.data;
  } catch (error: any) {
    if (errorHandler) {
      errorHandler(error);
    }
    if (suspressError) {
      return <AdrPostalPostResultDTO>{};
    }
    throw error;
  }
};

const createNewEmpty = async (
    apiClient: AxiosInstance,
    contactId: string,
    suspressError: boolean = true,
    errorHandler?: ErrorHandler<any>,
): Promise<AdrPostalGetResultDTO> => {
  try {
    const response = await apiClient.get<AdrPostalGetResultDTO>(
        '/api/companies/v1/' + UserService.getCompanyId() + '/entity/' + contactId + '/addresses/postal/newEmpty',
    );
    return response.data;
  } catch (error: any) {
    if (errorHandler) {
      errorHandler(error);
    }
    if (suspressError) {
      return <AdrPostalGetResultDTO>{};
    }
    throw error;
  }
};

const load = async (
    apiClient: AxiosInstance,
    contactId: string,
    id: string,
    suspressError: boolean = true,
    errorHandler?: ErrorHandler<any>,
): Promise<AdrPostalGetResultDTO> => {
  try {
    const response = await apiClient.get<AdrPostalGetResultDTO>(
        '/api/companies/v1/' + UserService.getCompanyId() + '/entity/' + contactId + '/addresses/postal',
    );
    return response.data;
  } catch (error: any) {
    if (errorHandler) {
      errorHandler(error);
    }
    if (suspressError) {
      return <AdrPostalGetResultDTO>{};
    }
    throw error;
  }
};

const update = async (
    object: AddressPostalDto,
    apiClient: AxiosInstance,
    suspressError: boolean = true,
    callback?: UpdateCallback<AdrPostalPutResultDTO>,
    errorHandler?: ErrorHandler<any>,
): Promise<AdrPostalPutResultDTO> => {
  try {
    const response = await apiClient.put<AdrPostalPutResultDTO>(
        '/api/companies/v1/' + UserService.getCompanyId() + '/entity/' + object.ownerId + '/addresses/postal',
        object,
    );

    if (callback) {
      callback(response.data);
    }

    return response.data;
  } catch (error: any) {
    if (errorHandler) {
      errorHandler(error);
    }
    if (suspressError) {
      return <AdrPostalGetResultDTO>{};
    }
    throw error;
  }
};

/*
//added by Simon to fetch Example Data
const getLegalEntitiesByCompany = async (companyId: string): Promise<{ data: AddressPostalDto[] }> => {
  // Simulation of API Call
  // toDo: Change to real API CALL
  return new Promise((resolve) => {
    setTimeout(() => {
      const company = addresses_postal.find((address) => address.id === companyId);
      resolve({ data: company ? company.legalEntities || [] : [] });
    }, 1000);
  });
};
*/

 
const AddressPostalService = {
  list,
  listEx,
  createNew,
  remove,
  update,
  load,
  createNewEmpty,
  
};

export default AddressPostalService;
