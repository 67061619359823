import { AddressPostalDto } from "./addresses_postal";
import { DocumentParticipantDTO } from "./ebl";
import { PartyDTO } from "./party";

const createDocumentParticipantDTO = (entity: PartyDTO, role: string): DocumentParticipantDTO => {
    return {
        companyId: entity.companyId,
        ownerId: entity.id,
        role: role,
    };

};


export const ROLE_SHIPPER = 'shipper';
export const ROLE_CARRIER = 'carrier';
export const ROLE_CONSIGNEE = 'consignee';
export const ROLE_DELIVERY = 'forDeliveryOfGoodsParty';
export const ROLE_NOTIFY = 'notifyParty';



const DocumentParticipantUtil = {
    createDocumentParticipantDTO,
};


export default DocumentParticipantUtil;
