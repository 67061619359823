import { Popover } from 'antd';
import { FaExclamationCircle } from 'react-icons/fa';

import { baseColors } from '../theme';

const InfoIcon = (props: Record<string, any>) => {
  return <FaExclamationCircle {...props} style={{ color: baseColors.primary }} />;
};

export const InfoPopover = ({ title }: { title: string }) => {
  return (
    <Popover
      arrow={false}
      placement="right"
      overlayClassName="haptik"
      content={
        <div className="flex items-center gap-3" style={{ maxWidth: '600px' }}>
          <span>
            <InfoIcon />
          </span>
          {title}
        </div>
      }
    >
      <>
        <InfoIcon />
      </>
    </Popover>
  );
};

export const InfoSuffix = ({ title }: { title: string }) => {
  return <InfoPopover title={title} />;
};
