import { notification } from 'antd';
import { NotificationInstance } from 'antd/es/notification/interface';
import { createContext, ReactNode } from 'react';

export type GlobalNotificationsContextType = {
  notificationAPI?: NotificationInstance;
};

export const GlobalNotificationsContext = createContext<GlobalNotificationsContextType>({});

export const GlobalNotificationWrapper = ({ children }: { children: ReactNode }) => {
  const [api, contextHolder] = notification.useNotification();
  return (
    <GlobalNotificationsContext.Provider value={{ notificationAPI: api }}>
      {children}
      {contextHolder}
    </GlobalNotificationsContext.Provider>
  );
};
