import { Typography } from 'antd';
import { ReactNode } from 'react';

import { baseColors } from '../theme';

export const PureInputLabel = ({
  label,
  className,
  children,
  required,
}: {
  label?: string;
  required?: boolean;
  className?: string;
  children: ReactNode;
}) => {
  return (
    <div className={className}>
      {required && <span style={{ color: baseColors.error }}>* </span>}
      {label && <Typography.Text style={{ display: 'inline-block', marginBlockEnd: '8px' }}>{label}</Typography.Text>}
      {children}
    </div>
  );
};
