import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { baseColors } from '../theme';

const StyledContainer = styled.div`
  padding: 30px;
  border-radius: 10px;
  background: ${baseColors.white};
  box-shadow: ${baseColors.boxShadowPrimary};
`;

type ContainerProps = {
  children: ReactNode;
  className?: string;
};

export const Container: React.FC<ContainerProps> = ({ className, children }) => {
  return <StyledContainer className={className}>{children}</StyledContainer>;
};
