import { isArray } from 'lodash-es';
import { createContext, ReactNode, useCallback, useState } from 'react';

import { StepsNames } from './StepsNames';

export type Mode = 'create' | 'edit';

export type BillOfLadingStateContextType = {
  mode: Mode;
  disabled: boolean;
  doingRequest: boolean;
  invalidSteps: StepsNames[];
  createEblInitiated: boolean;
  updateCreateEblInitiated: (value: boolean) => void;
  updateInvalidSteps: (step: StepsNames | StepsNames[]) => void;
  updateDisable: (v: boolean) => void;
  updateDoingRequest: (v: boolean) => void;
};

const INIT_CONTEXT_DATA: BillOfLadingStateContextType = {
  mode: 'create',
  disabled: false,
  doingRequest: false,
  invalidSteps: [],
  createEblInitiated: false,
  updateInvalidSteps: () => {},
  updateCreateEblInitiated: () => {},
  updateDisable: () => {},
  updateDoingRequest: () => {},
};

export const BillOfLadingStateContext = createContext<BillOfLadingStateContextType>(INIT_CONTEXT_DATA);

export const BillOfLadingStateWrapper = ({ mode = 'create', children }: { mode?: Mode; children: ReactNode }) => {
  const [disabled, setDisabled] = useState(INIT_CONTEXT_DATA.disabled);
  const [doingRequest, setDoingRequest] = useState(INIT_CONTEXT_DATA.doingRequest);
  const [invalidSteps, setInvalidSteps] = useState<StepsNames[]>([]);
  const [createEblInitiated, setCreateEblInitiated] = useState(false);

  const updateInvalidSteps = useCallback(
    (steps: StepsNames | StepsNames[]) => {
      if (isArray(steps)) {
        setInvalidSteps(steps);
      } else if (!invalidSteps.includes(steps)) {
        setInvalidSteps([steps]);
      }
    },
    [invalidSteps],
  );

  return (
    <BillOfLadingStateContext.Provider
      value={{
        mode,
        disabled,
        doingRequest,
        invalidSteps: invalidSteps,
        updateInvalidSteps: updateInvalidSteps,
        createEblInitiated,
        updateCreateEblInitiated: setCreateEblInitiated,
        updateDisable: setDisabled,
        updateDoingRequest: setDoingRequest,
      }}
    >
      {children}
    </BillOfLadingStateContext.Provider>
  );
};
