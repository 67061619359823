import type { UploadFile, UploadProps } from 'antd';
import { Button } from 'antd';
import { useState } from 'react';
import { FiDownload } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import UploadModal from '../../components/UploadModal';

export default function HeaderActions() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const navigate = useNavigate();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setFileList([]);
  };

  const handleUpload = (info: any) => {
    setFileList(info.fileList);
  };

  const uploadProps: UploadProps = {
    name: 'file',
    multiple: false,
    fileList,
    onChange: handleUpload,
    accept:
      'text/xml, application/excel, application/vnd.ms-excel, application/x-excel, application/x-msexcel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  };

  const handleCreateBillOfLading = () => {
    navigate('/bills-of-lading/create');
  };
  const handleCreateTransportDocument = () => {
    navigate('/choose-ebl');
  };

  return (
    <>
      <Button className="mr-4" type="primary" onClick={handleCreateBillOfLading}>
        Create eBL
      </Button>
      <Button className="mr-4" type="primary" onClick={handleCreateTransportDocument}>
        Choose transport type
      </Button>
      <Button className="flex items-center" onClick={showModal}>
        Import Data <FiDownload size={16} className="ml-3" />
      </Button>

      <UploadModal
        isOpen={isModalOpen}
        uploadProps={uploadProps}
        title={'Import Bill of Lading'}
        text={'Please, upload the necessary configuration (XML or EXCEL)'}
        submitText={'Import Data'}
        onClose={handleCancel}
      />
    </>
  );
}
