import { AxiosInstance } from 'axios';
import UserService from 'services/UserService';
import {
  DeleteResultDTO,
  ErrorHandler,
  GetResultDTO,
  PostResultDTO,
  ListResultDTO,
  PutResultDTO,
  UpdateCallback,
} from './hapibase';
 

export type PartyDTO = {
  id: string; // party id
  companyId: string;
  organizationUnitId: string;
  name: string; // user name
  tags:string[];
  email:string;
  active: boolean;
  published:boolean;
  created: string;
  updated: string;
};


export type PartyGetResultDTO = GetResultDTO<PartyDTO>;
export type PartyListResultDTO = ListResultDTO<PartyDTO>;
export type PartySearchListResultDTO = ListResultDTO<PartyDTO>;
export type PartyPutResultDTO = PutResultDTO<PartyDTO>;
export type PartyPostResultDTO = PostResultDTO<PartyDTO>;
export type PartyDeleteResultDTO = DeleteResultDTO<PartyDTO>;


/**
 * search all user mapping to a uo
 *
 * @param apiClient
 * @param uoId
 * @param errorHandler
 * @returns
 */
const search = async (
  apiClient: AxiosInstance,
  query:string, // TODO query
  suspressError: boolean = true,
  errorHandler?: ErrorHandler<any>,
): Promise<PartyListResultDTO> => {
  try {
    const response = await apiClient.get<PartyListResultDTO>(
      '/api/companies/v1/' + UserService.getCompanyId() + '/parties/search',
    );
    return response.data;
  } catch (error: any) {
    if (errorHandler) {
      errorHandler(error);
    }
    if (suspressError) {
      return <PartyListResultDTO>{};
    }
    throw error;
  }
};

/**
 * list all user mapping to a uo
 *
 * @param apiClient
 * @param uoId
 * @param errorHandler
 * @returns
 */
const list = async (
  apiClient: AxiosInstance,
  suspressError: boolean = true,
  errorHandler?: ErrorHandler<any>,
): Promise<PartyListResultDTO> => {
  try {
    const response = await apiClient.get<PartyListResultDTO>(
      '/api/companies/v1/' + UserService.getCompanyId() + '/parties',
    );
    return response.data;
  } catch (error: any) {
    if (errorHandler) {
      errorHandler(error);
    }
    if (suspressError) {
      return <PartyListResultDTO>{};
    }
    throw error;
  }
};

/**
 *
 * @param apiClient
 * @param id
 * @param errorHandler
 * @returns
 */
const remove = async (
  apiClient: AxiosInstance,
  partyId: string,
  suspressError: boolean = true,
  errorHandler?: ErrorHandler<any>,
): Promise<PartyDeleteResultDTO> => {
  try {
    const response = await apiClient.delete<PartyDeleteResultDTO>(
      '/api/companies/v1/' + UserService.getCompanyId() + '/parties/' + partyId,
    );
    return response.data;
  } catch (error: any) {
    if (errorHandler) {
      errorHandler(error);
    }
    if (suspressError) {
      return <PartyDeleteResultDTO>{};
    }
    throw error;
  }
};

const createNew = async (
  apiClient: AxiosInstance,
  newEntry: PartyDTO,
  suspressError: boolean = true,
  errorHandler?: ErrorHandler<any>,
): Promise<PartyPostResultDTO> => {
  try {
    const response = await apiClient.post<PartyPostResultDTO>(
      '/api/companies/v1/' + UserService.getCompanyId() + '/parties',
      newEntry,
    );
    return response.data;
  } catch (error: any) {
    if (errorHandler) {
      errorHandler(error);
    }
    if (suspressError) {
      return <PartyPostResultDTO>{};
    }
    throw error;
  }
};

const createNewEmpty = async (
  apiClient: AxiosInstance,
  suspressError: boolean = true,
  errorHandler?: ErrorHandler<any>,
): Promise<PartyPostResultDTO> => {
  try {
    const response = await apiClient.get<PartyPostResultDTO>(
      '/api/companies/v1/' + UserService.getCompanyId() + '/parties/newEmpty',
    );
    return response.data;
  } catch (error: any) {
    if (errorHandler) {
      errorHandler(error);
    }
    if (suspressError) {
      return <PartyGetResultDTO>{};
    }
    throw error;
  }
};

const load = async (
  apiClient: AxiosInstance,
  id: string,
  suspressError: boolean = true,
  errorHandler?: ErrorHandler<any>,
): Promise<PartyGetResultDTO> => {
  try {
    const response = await apiClient.get<PartyGetResultDTO>(
      '/api/companies/v1/' + UserService.getCompanyId() + '/parties/' + id,
    );
    return response.data;
  } catch (error: any) {
    if (errorHandler) {
      errorHandler(error);
    }
    if (suspressError) {
      return <PartyGetResultDTO>{};
    }
    throw error;
  }
};

const update = async (
  object: PartyDTO,
  apiClient: AxiosInstance,
  suspressError: boolean = true,
  callback?: UpdateCallback<PartyPutResultDTO>,
  errorHandler?: ErrorHandler<any>,
): Promise<PartyPutResultDTO> => {
  try {
    const response = await apiClient.put<PartyPutResultDTO>(
      '/api/companies/v1/' + UserService.getCompanyId() + '/parties/' + object.id,
      object,
    );

    if (callback) {
      callback(response.data);
    }

    return response.data;
  } catch (error: any) {
    if (errorHandler) {
      errorHandler(error);
    }
    if (suspressError) {
      return <PartyGetResultDTO>{};
    }
    throw error;
  }
};

const PartyService = {
  list,
  createNew,
  remove,
  update,
  load,
  createNewEmpty,
  search
};

export default PartyService;
