import { Button } from 'antd';
import styled from 'styled-components';

import { baseColors } from '../theme';

export const StyledButton = styled(Button)`
  &.ant-btn-text {
    &:disabled {
      color: ${baseColors.primary};
    }

    &:hover,
    &:active {
      background-color: transparent !important;
      color: ${baseColors.primaryActive} !important;
    }
  }
`;
