export enum UserRole {
  BUYER = 'BUYER',
  CARRIER = 'CARRIER',
  SHIPPER = 'SHIPPER',
  BANK = 'bank',
}

export const UserRoles: { value: string; label: string }[] = [
  { value: UserRole.BUYER, label: 'Buyer' },
  { value: UserRole.CARRIER, label: 'Carrier' },
  { value: UserRole.SHIPPER, label: 'Shipper' },
  { value: UserRole.BANK, label: 'Bank' },
];

export type UserPrivileges = {
  AGREE_JOINING_CHANNEL: boolean;
  ARREST_BILL_OF_LADING: boolean;
  CHANGE_ACCOUNT_CONFIGURATION: boolean;
  CREATE_BILL_OF_LADING: boolean;
  CREATE_DRAFT: boolean;
  DELETE_BILL_OF_LADING: boolean;
  ENDORSE_BILL_OF_LADING: boolean;
  KEEP_BILL_OF_LADING: boolean;
  MANAGE_PARTICIPATING_PARTIES: boolean;
  PRINT_BILL_OF_LADING: boolean;
  READ_BILL_OF_LADING: boolean;
  READ_BILL_OF_LADING_OVERVIEW: boolean;
  SIGN_BILL_OF_LADING: boolean;
  SIGN_DELIVERY: boolean;
  UPDATE_BILL_OF_LADING: boolean;
};

export type User = {
  aud: string[];
  auth_time: number;
  azp: string;
  companyName: string;
  exp: number;
  group: string[];
  iat: number;
  iss: string;
  jti: string;
  nonce: string;
  preferred_username: string;
  realm_access: {
    roles: string[];
  };
  resource_access: {
    account: {
      roles: string[];
    };
    'haptik-backend': {
      roles: string[];
    };
  };
  role: UserRole;
  scope: string;
  session_state: string;
  sid: string;
  sub: string;
  typ: string;
  userId: string;
  user_privileges: UserPrivileges;
};
