import Keycloak from 'keycloak-js';

import { UserRole } from './UserModel';

const apiBaseUrl = process.env.REACT_APP_BASE_API_URL + '/auth';

const keycloakConfig = {
  url: apiBaseUrl,
  realm: 'haptik',
  clientId: 'haptik-frontend',
};

const _kc = new Keycloak(keycloakConfig);

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback: () => void) => {
  _kc
    .init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      pkceMethod: 'S256',
    })
    .then((authenticated) => {
      if (!authenticated) {
        // eslint-disable-next-line no-console
        console.error('user is not authenticated..!');
        doLogin();
      } else {
        // eslint-disable-next-line no-console
        // console.log('User attributes:', _kc.tokenParsed);
        onAuthenticatedCallback();
      }
    })
    // eslint-disable-next-line no-console
    .catch(console.error);
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback: () => any) => _kc.updateToken(5).then(successCallback).catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const getFullName = () => _kc.tokenParsed?.given_name + ' ' + _kc.idTokenParsed!.family_name;

const hasRole = (roles: string[]) => roles.some((role) => _kc.hasRealmRole(role));

const getClientRoles = () => {
  if (_kc.tokenParsed) {
    const clientRoles = _kc.tokenParsed.resource_access?.[keycloakConfig.clientId]?.roles;
    return clientRoles || [];
  }
  return [];
};

const getRealmRoles = () => {
  if (_kc.tokenParsed) {
    const realmRoles = _kc.tokenParsed.realm_access?.roles;
    return realmRoles || [];
  }
  return [];
};

const getCompanyName = () => _kc.tokenParsed?.companyName;

const getWalletAddress = () => _kc.tokenParsed?.walletAddress;

const getUserId = () => _kc.tokenParsed?.userId;

const getUserRole = () => _kc.tokenParsed?.role;

const getUserPrivileges = () => _kc.tokenParsed?.user_privileges;

const isCarrier = (objectRoles: string[] | null | undefined) => {
  return checkRole(objectRoles, UserRole.CARRIER);
};
const isCarrierKC = () => _kc.tokenParsed?.role?.toUpperCase() === UserRole.CARRIER;

const checkRole = (objectRoles: string[] | null | undefined, checkRole: string) => {
  if (objectRoles === null || objectRoles === undefined || objectRoles.length == 0) {
    return false;
  }
  if (objectRoles.filter((role) => role.toUpperCase() === checkRole).length > 0) {
    return true;
  }
  return false;
};

const isShipper = (objectRoles: string[] | null | undefined) => {
  return checkRole(objectRoles, UserRole.SHIPPER);
};

const isConsignee = (objectRoles: string[] | null | undefined) => {
  return checkRole(objectRoles, UserRole.BUYER);
};

const isBank = (objectRoles: string[] | null | undefined) => {
  return checkRole(objectRoles, UserRole.BANK);
};

const getCompanyId = (): string => _kc.tokenParsed?.companyId;

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  getFullName,
  hasRole,
  getClientRoles,
  getRealmRoles,
  getUserRole,
  isCarrier,
  isShipper,
  isConsignee,
  isBank,
  getUserId,
  getCompanyName,
  getUserPrivileges,
  getCompanyId,
  getWalletAddress,
  isCarrierKC,
};

export default UserService;
