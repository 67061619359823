import { ReactElement } from 'react';
import {
  AiFillBook,
  AiFillCheckSquare,
  AiFillCompass,
  AiFillFlag,
  AiFillMinusSquare,
  AiFillNotification,
  AiFillRocket,
  AiFillShopping,
} from 'react-icons/ai';
import { IoLocation } from 'react-icons/io5';
import { PiCalendarBlankFill, PiCarProfileFill } from 'react-icons/pi';

type FilterItem = {
  label: string;
  icon: ReactElement;
  id: number;
  active?: boolean;
};

export const BILL_OF_LADING_FILTERS: FilterItem[] = [
  {
    id: 1,
    label: 'eBL status',
    icon: <AiFillFlag />,
  },
  {
    id: 2,
    label: 'Creation date (last modification)',
    icon: <PiCalendarBlankFill />,
  },
  {
    id: 3,
    label: 'eBL number (reference number)',
    icon: <AiFillBook />,
  },
  {
    id: 4,
    label: 'Carrier name',
    icon: <AiFillRocket />,
  },
  {
    id: 5,
    label: 'Consignee name',
    icon: <AiFillCheckSquare />,
  },
  {
    id: 6,
    label: 'Notify party',
    icon: <AiFillNotification />,
  },
  {
    id: 7,
    label: 'Loading port',
    icon: <IoLocation />,
  },
  {
    id: 8,
    label: 'Discharging port',
    icon: <AiFillCompass />,
  },
  {
    id: 9,
    label: 'Type of cargo',
    icon: <AiFillShopping />,
  },
  {
    id: 10,
    label: 'Mode of transport',
    icon: <PiCarProfileFill />,
  },
  {
    id: 11,
    label: 'Export',
    icon: <AiFillMinusSquare />,
  },
];
