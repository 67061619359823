import { Button, Layout, Typography } from 'antd';
import { ReactNode, useCallback, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import { useEvent } from 'react-use';

const { Content } = Layout;
const { Title } = Typography;

export default function GlobalErrorHandler({ children }: { children: ReactNode }) {
  // eslint-disable-next-line
  const [currentError, setCurrentError] = useState<string | null>(null);
  const clearError = useRef(() => setCurrentError(null)).current;
  const navigate = useNavigate();

  const processError = useCallback((error: unknown, info?: unknown) => {
    // eslint-disable-next-line no-console
    console.error(`Error caught in TCError handler`, error);

    if (info) {
      // eslint-disable-next-line no-console
      console.error(`Error info:`, info);
    }
    setCurrentError(`${error}`);
  }, []);

  const handleRejection = useCallback(
    (event: PromiseRejectionEvent) => {
      event.preventDefault();
      processError(event.reason);
    },
    [processError],
  );

  const tryAgain = () => {
    clearError();
    navigate(0);
  };

  useEvent('unhandledrejection', handleRejection);

  return (
    <ErrorBoundary
      onError={processError}
      FallbackComponent={() => (
        <Layout>
          <Content className="p-6 text-center">
            <Title>Please contact support if this problem occurs repeatedly.</Title>
            <Button type="primary" className="mt-4" onClick={tryAgain}>
              Try again
            </Button>
          </Content>
        </Layout>
      )}
    >
      {children}
    </ErrorBoundary>
  );
}
