import { ThemeConfig } from 'antd';

export const baseColors = {
  primary: '#0066ff',
  primaryActive: '#004ec4',
  primaryLight: '#4e95ff',
  error: '#ba0000',
  warning: '#fcad14',
  success: '#45aa13',
  white: '#ffffff',
  whiteOpacity: 'rgba(255, 255, 255, 0.898)',
  placeholder: '#AAC4DF',
  border: '#AAC4DF',
  option: '#e1edff',
  text: '#222222',
  icon: '#C7DBF0',
  iconBg: '#F0F7FF',
  plateCardPrimary: '#FFCB77',
  plateCardSecondary: '#6AB7FF',
  plateCardThird: '#81E284',
  plateCardFourth: '#FF8888',
  plateCardFifth: '#C488FF',
  bgContent: '#fbfdff',
  DRAFT: '#CCE7FF',
  PENDING_APPROVAL: '#FFCB77',
  APPROVED: '#6AB7FF',
  AMENDED: '#ffa36a',
  COMPLETED: '#81E284',
  VOIDED: '#C488FF',
  ISSUED: '#FF8888',
  grayLight: '#9FB2C5',
  lineGraph: '#DAEBFC',
  textColorInfoCard: '#5b5c60',
  iconHeaderUser: '#e1edff',
  info: '#DCF4FF',
  draggerBg: '#F0F7FF',
  errorHover: '#A70000',
  errorDisable: '#FF9191',
  boxShadowPrimary: '0 5px 20px 0 rgba(104, 165, 255, 0.15)',
  boxShadowSecondary: '0 5px 20px 0 rgba(104, 165, 255, 0.1)',
};

const theme: ThemeConfig = {
  token: {
    colorInfo: baseColors.primary,
    colorError: baseColors.error,
    colorWarning: baseColors.warning,
    colorSuccess: baseColors.success,
    colorPrimary: baseColors.primary,
    fontFamily: 'Mulish, serif',
    fontSize: 14,
    boxShadow: '0 0 20px 0 #68A5FF26',
    borderRadius: 8,
  },
  components: {
    Typography: {
      fontSizeHeading1: 40,
      fontSizeHeading2: 30,
      fontSizeHeading3: 25,
      fontSizeHeading4: 22,
      fontSizeHeading5: 20,
      fontWeightStrong: 700,
    },
    Button: {
      colorPrimaryActive: baseColors.primaryActive,
      colorPrimaryHover: baseColors.primaryActive,
      borderColorDisabled: 'transparent',
      colorText: baseColors.primary,
      colorBgTextActive: 'transparent',
      colorBgTextHover: 'transparent',
      colorErrorBg: 'transparent',
      colorErrorHover: baseColors.errorHover,
      colorTextDisabled: baseColors.white,
      defaultBorderColor: baseColors.primary,
      colorBorder: baseColors.primaryLight,
      colorBgContainerDisabled: baseColors.primaryLight,
      textHoverBg: baseColors.primaryLight,
      primaryColor: baseColors.white,
      controlHeight: 42,
      borderRadius: 8,
      fontFamily: '"Rubik", sans-serif',
    },
    Radio: {
      colorBorder: baseColors.primary,
      colorText: baseColors.text,
      colorTextPlaceholder: baseColors.placeholder,
    },
    Select: {
      controlHeight: 38,
      borderRadius: 8,
      colorBorder: baseColors.border,
      colorTextPlaceholder: baseColors.placeholder,
      optionSelectedBg: baseColors.option,
      optionActiveBg: baseColors.option,
      optionSelectedFontWeight: 400,
      colorPrimaryHover: baseColors.primary,
    },
    DatePicker: {
      fontWeightStrong: 400,
      colorBorder: baseColors.border,
      colorTextPlaceholder: baseColors.placeholder,
      colorPrimaryHover: baseColors.primary,
      controlHeight: 38,
      borderRadius: 8,
    },
    Input: {
      controlHeight: 38,
      borderRadius: 8,
      colorBorder: baseColors.border,
      colorTextPlaceholder: baseColors.placeholder,
      colorPrimaryHover: baseColors.primary,
    },
    Menu: {
      itemColor: baseColors.text,
      itemBg: baseColors.white,
      itemSelectedBg: baseColors.option,
      itemSelectedColor: baseColors.text,
      colorIcon: baseColors.icon,
      iconSize: 20,
      lineWidth: 0,
      itemHeight: 54,
      size: 20,
    },
    Layout: {
      siderBg: baseColors.white,
      triggerBg: baseColors.white,
      triggerColor: baseColors.text,
    },
  },
};

export default theme;
