import { isNil, isString } from 'lodash-es';
import { BehaviorSubject, EMPTY, of, Subscription, switchMap } from 'rxjs';

import { apiClient, BASE_API_URL, BASE_WEB_SOCKET_URL } from '../api-client';
import { NotificationEntity, NotificationExtendEntity } from '../models';
import UserService from './UserService';

export type UnreadNotificationInfo = {
  notifications: NotificationExtendEntity[];
  unread: number;
  newUnread: number;
};

export type MessageCallback = (message: NotificationEntity) => void;
export type MessagesInfoCallback = (info: UnreadNotificationInfo) => void;

class NotificationConnection {
  public static readonly MAX_RETRIES = 10;

  private ws!: WebSocket;
  private closedManually = true;
  private retriesCount = 0;
  private notifications: NotificationExtendEntity[] = [];
  private unreadInfo: Pick<UnreadNotificationInfo, 'newUnread' | 'unread'> = { newUnread: 0, unread: 0 };
  private messageInfo$ = new BehaviorSubject<UnreadNotificationInfo | null>(null);
  private messageReceive$ = new BehaviorSubject<NotificationEntity | null>(null);
  private messageInfoEvent$ = this.messageInfo$
    .asObservable()
    .pipe(switchMap((info) => (isNil(info) ? EMPTY : of(info))));
  public messageEvent$ = this.messageReceive$
    .asObservable()
    .pipe(switchMap((info) => (isNil(info) ? EMPTY : of(info))));

  private pingPongTimerId: ReturnType<typeof setInterval> | null = null;

  constructor() {}

  subscribe(callback: MessageCallback): Subscription {
    return this.messageEvent$.subscribe(callback);
  }

  infoSubscribe(callback: MessagesInfoCallback): Subscription {
    return this.messageInfoEvent$.subscribe(callback);
  }

  async getUnreadNotifications(): Promise<UnreadNotificationInfo> {
    const apiBaseUrl = process.env.REACT_APP_BASE_API_URL || BASE_API_URL;
    return apiClient
      .get<NotificationExtendEntity[]>('/api/users/v1/notifications')
      .then((response) => response.data)
      .then((notifications) => {
        this.unreadInfo = notifications.reduce(
          (memo, notification) => {
            if (!this.notifications.some(({ id }) => id === notification.id)) {
              memo.newUnread = notification.isRead ? memo.newUnread : ++memo.newUnread;
            }
            memo.unread = notification.isRead ? memo.unread : ++memo.unread;
            return memo;
          },
          { newUnread: 0, unread: 0 },
        );

        this.notifications = notifications;
        return { notifications, ...this.unreadInfo };
      });
  }

  refreshMessageInfo(): void {
    this.getUnreadNotifications().then((info) => {
      this.messageInfo$.next(info);
    });
  }

  connect(): void {
  /*
    if (this.ws?.readyState !== WebSocket.OPEN && this.ws?.readyState !== WebSocket.CONNECTING) {
      const token = UserService.getToken();
      if (isString(token)) {
        const webSocketBaseUrl = process.env.REACT_APP_BASE_WEB_SOCKET_URL || BASE_WEB_SOCKET_URL;
        this.ws = new WebSocket(`${webSocketBaseUrl}/ws/notifications?XWSAuthorization=${token}`);

        this.ws.addEventListener('error', this.handleError);
        this.ws.addEventListener('open', this.handleOpenConnection);
        this.ws.addEventListener('message', this.handleMessage);
        this.ws.addEventListener('close', this.handleCloseConnection);
      }
    }
	*/
  }

  // the method used if a connection failed or an unexpected disconnection occurred
  reconnect(): void {
    if (this.closedManually) {
      return;
    }

    if (this.retriesCount < NotificationConnection.MAX_RETRIES) {
      setTimeout(() => {
        this.retriesCount++;
        this.connect();
      }, 1000);
    } else if (this.retriesCount < NotificationConnection.MAX_RETRIES + 3) {
      setTimeout(() => {
        this.reconnect();
      }, 10000);
    }
  }

  initiate(): void {
    this.closedManually = false;
    this.getUnreadNotifications().then((info) => {
      this.messageInfo$.next(info);
    });
    this.connect();
  }

  close(): void {
    if (this.ws?.readyState === WebSocket.OPEN) {
      this.ws.close();
    }
    this.closedManually = true;
  }

  private handleCloseConnection = (): void => {
    console.info('Notification Websocket is closed');
    this.clearPingPong();
    this.reconnect();
  };

  private handleOpenConnection = (): void => {
    this.setupPingPong();
    console.info('Notification Websocket is connected');
  };

  private handleMessage = (event: MessageEvent<string>): void => {
    if (event.data && !event.data.toLowerCase().includes('pong')) {
      const message = (JSON.parse(event.data) as NotificationExtendEntity[])[0];
      this.messageReceive$.next(message);
      this.refreshMessageInfo();
    }
  };

  private handleError = (event: Event): void => {
    console.error(event);
    this.reconnect();
  };

  private clearPingPong(): void {
    if (this.pingPongTimerId) {
      clearInterval(this.pingPongTimerId);
    }
  }

  private setupPingPong(): void {
    this.clearPingPong();

    this.pingPongTimerId = setInterval(() => {
      if (this.ws?.readyState === WebSocket.OPEN) {
        this.ws?.send('{"type": "PING"}');
      }
    }, 3000);
  }
}

export const WS = new NotificationConnection();
