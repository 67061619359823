import { Button, Checkbox, DatePicker, Flex, Modal, Select, Typography } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import dayjs from 'dayjs';
import React, { Fragment, ReactElement, ReactNode, useState } from 'react';
import { VscChromeClose } from 'react-icons/vsc';
import styled from 'styled-components';

import { Option as OptionItem } from '../models';
import { BILL_OF_LADING_FILTERS } from '../pages/BillsOfLading/BillOfLadingCreate/BillOfLadingFilters';
import { StyledMenuContainerItem } from '../pages/BillsOfLading/BillOfLadingCreate/MenuNavigation';
import { baseColors } from '../theme';

const { Title, Paragraph } = Typography;
const { Group: CheckboxGroup } = Checkbox;
const { RangePicker } = DatePicker;

type StatusType = 'DRAFT' | 'PENDING_APPROVAL' | 'AMENDED' | 'APPROVED' | 'COMPLETED' | 'VOIDED' | 'ISSUED';

type OptionType = OptionItem;

type FilterItem = {
  id: number;
  label: string;
  icon: ReactElement;
  active?: boolean;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

type Option = OptionItem<StatusType>;

type RenderMenuContainerItemProps = {
  id: number;
  label: string;
  icon: ReactElement;
  active?: boolean;
};

const checkboxOptions: OptionItem<StatusType>[] = [
  // { value: 'DRAFT', label: 'Draft' },
  { value: 'PENDING_APPROVAL', label: 'Pending Approval' },
  // { value: 'AMENDED', label: 'Amended' },
  { value: 'APPROVED', label: 'Approved' },
  { value: 'ISSUED', label: 'Issued' },
  { value: 'COMPLETED', label: 'Completed' },
  { value: 'VOIDED', label: 'Voided' },
];

const referenceOption: OptionType[] = [
  { value: 'eBL 334556322', label: 'eBL 334556322' },
  { value: 'eBL 37847387', label: 'eBL 37847387' },
  { value: 'eBL 37842222', label: 'eBL 37842222' },
  { value: 'eBL 78600443', label: 'eBL 78600443' },
  { value: 'eBL 123456700', label: 'eBL 123456700' },
];

const carrierNameOption: OptionType[] = [
  { value: 'Carrier name 11', label: 'Carrier name 11' },
  { value: 'Carrier name 12', label: 'Carrier name 12' },
  { value: 'Carrier name 13', label: 'Carrier name 13' },
  { value: 'Carrier name 14', label: 'Carrier name 14' },
  { value: 'Carrier name 15', label: 'Carrier name 15' },
];

const consigneeNameOption: OptionType[] = [
  { value: 'Consignee name 11', label: 'Consignee name 11' },
  { value: 'Consignee name 12', label: 'Consignee name 12' },
  { value: 'Consignee name 13', label: 'Consignee name 13' },
  { value: 'Consignee name 14', label: 'Consignee name 14' },
  { value: 'Consignee name 15', label: 'Consignee name 15' },
];

const notifyPartyOption: OptionType[] = [
  { value: 'Notify party name 11', label: 'Notify party name 11' },
  { value: 'Notify party name 12', label: 'Notify party name 12' },
  { value: 'Notify party name 13', label: 'Notify party name 13' },
  { value: 'Notify party name 14', label: 'Notify party name 14' },
  { value: 'Notify party name 15', label: 'Notify party name 15' },
];

const loadingPortOption: OptionType[] = [
  { value: 'Loading port name 11', label: 'Loading port name 11' },
  { value: 'Loading port name 12', label: 'Loading port name 12' },
  { value: 'Loading port name 13', label: 'Loading port name 13' },
  { value: 'Loading port name 14', label: 'Loading port name 14' },
  { value: 'Loading port name 15', label: 'Loading port name 15' },
];

const dischargingPortOption: OptionType[] = [
  { value: 'Discharging port name 11', label: 'Discharging port name 11' },
  { value: 'Discharging port name 12', label: 'Discharging port name 12' },
  { value: 'Discharging port name 13', label: 'Discharging port name 13' },
  { value: 'Discharging port name 14', label: 'Discharging port name 14' },
  { value: 'Discharging port name 15', label: 'Discharging port name 15' },
];

const typeCargoOption: OptionType[] = [
  { value: 'Break bulk (BB)', label: 'Break bulk (BB)' },
  { value: 'Full container load (FCL)', label: 'Full container load (FCL)' },
  { value: 'Less than container load (LCL)', label: 'Less than container load (LCL)' },
];

const modeTransportOption: OptionType[] = [
  { value: 'Vessel', label: 'Vessel' },
  { value: 'Rail', label: 'Rail' },
  { value: 'Truck', label: 'Truck' },
  { value: 'Barge', label: 'Barge' },
];

const exportOption: OptionType[] = [
  { value: 'Place of receipt (PRE)', label: 'Place of receipt (PRE)' },
  { value: 'Port of loading (POL)', label: 'Port of loading (POL)' },
  { value: 'Port of discharge (POD)', label: 'Port of discharge (POD)' },
  { value: 'Place of delivery (PDE)', label: 'Place of delivery (PDE)' },
  { value: 'Pre-carriage form (PCF)', label: 'Pre-carriage form (PCF)' },
  {
    value: 'Pre-carriage under shipper`s responsibility (PSR)',
    label: 'Pre-carriage under shipper`s responsibility (PSR)',
  },
  { value: 'Onward in-land routing (OIR)', label: 'Onward in-land routing (OIR)' },
  { value: 'Deport release location (DRL)', label: 'Deport release location (DRL)' },
  { value: 'Booking part reference number (ORI)', label: 'Booking part reference number (ORI)' },
  { value: 'Origin of goods (IEL)', label: 'Origin of goods (IEL)' },
  {
    value: 'Container intermediate export stop off location (PTP)',
    label: 'Container intermediate export stop off location (PTP)',
  },
  {
    value: 'Requested transshipment port (RTP)',
    label: 'Requested transshipment port (RTP)',
  },
  {
    value: 'Full container drop-off location (FCD)',
    label: 'Full container drop-off location (FCD)',
  },
  {
    value: 'Empty container pick-up location (ECP)',
    label: 'Empty container pick-up location (ECP)',
  },
];

const StyledCheckboxGroup = styled(CheckboxGroup)`
  &.ant-checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

const StyledVscChromeClose = styled(VscChromeClose)`
  cursor: pointer;
  color: ${baseColors.icon};

  &:hover {
    color: ${baseColors.error};
  }
`;

const StyledRangePicker = styled(RangePicker)`
  &.ant-picker {
    .ant-picker-suffix {
      margin-left: auto;
    }
    .ant-picker-input {
      width: 78px;
    }
  }
`;

export default function FiltersModal({ isOpen, onClose }: Props) {
  const filters: FilterItem[] = BILL_OF_LADING_FILTERS;
  const defaultCheckedList: string[] = [];
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(defaultCheckedList);
  const [checkboxActive, setCheckboxActive] = useState<{ [key: string]: Option }>({});

  const handlerCheckedListFilter = (list: CheckboxValueType[]) => {
    setCheckedList(list);

    const updatedCheckboxActive = list.reduce(
      (acc, value) => {
        const item = checkboxOptions.find((option) => option.value === value);
        if (item) {
          acc[item.value] = item;
        }
        return acc;
      },
      {} as { [key: string]: Option },
    );

    setCheckboxActive(updatedCheckboxActive);
  };

  const handleRemoveFilter = (value: StatusType) => {
    const updatedList = checkedList.filter((item) => item !== value);
    handlerCheckedListFilter(updatedList);
  };

  const initialFilters = filters.map(({ id, label, icon }, index) => ({
    id,
    label,
    icon,
    active: index === 0,
  }));

  const [filtersItem, setFilters] = useState<FilterItem[]>(initialFilters);
  const [activeFilterId, setActiveFilterId] = useState<number>(1);
  const [selectedDates, setSelectedDates] = useState([]);
  const [formatSeparator, setFormatSeparator] = useState<string | null>(null);
  const [reference, setReference] = useState<string | null>();
  const [carrierName, setCarrierName] = useState<string | null>();
  const [consigneeName, setConsigneeName] = useState<string | null>();
  const [notifyParty, setNotifyParty] = useState<string | null>();
  const [loadingPort, setLoadingPort] = useState<string | null>();
  const [dischargingPort, setDischargingPort] = useState<string | null>();
  const [typeCargo, setTypeCargo] = useState<string | null>();
  const [modeTransport, setModeTransport] = useState<string | null>();
  const [exportData, setExport] = useState<string | null>();

  const handleClickFilter = (clickedFilter: FilterItem) => {
    const updatedFilters = filters.map((filter) => {
      return filter.id === clickedFilter.id ? { ...filter, active: true } : { ...filter, active: false };
    });
    setFilters(updatedFilters);
    setActiveFilterId(clickedFilter.id);
  };

  const handleDateChange = (dates: any) => {
    renderSeparator(dates);
    if (!dates) {
      setSelectedDates([]);
      return;
    }
    setSelectedDates(dates.map((date: dayjs.Dayjs) => date.format(dateFormat)));
  };

  const renderSeparator = (dates: any) => {
    if (dates?.length === 2) {
      setFormatSeparator('-');
    } else {
      setFormatSeparator('');
    }
  };
  const dateFormat = 'DD/MM/YYYY';

  const handleReference = (val: string) => {
    setReference(val);
  };

  const handleCarrierName = (val: string) => {
    setCarrierName(val);
  };

  const handleConsigneeName = (val: string) => {
    setConsigneeName(val);
  };

  const handleNotifyParty = (val: string) => {
    setNotifyParty(val);
  };

  const handleLoadingPort = (val: string) => {
    setLoadingPort(val);
  };

  const handleDischargingPort = (val: string) => {
    setDischargingPort(val);
  };

  const handleTypeCargo = (val: string) => {
    setTypeCargo(val);
  };

  const handleModeTransport = (val: string) => {
    setModeTransport(val);
  };

  const handleExport = (val: string) => {
    setExport(val);
  };

  type RenderFilterItemProps = {
    children: ReactNode;
  };

  const RenderFilterItem: React.FC<RenderFilterItemProps> = ({ children }) => {
    return (
      <Flex align="center" justify="space-between" className="mb-4 text-sm" style={{ padding: '0 10px 0 54px' }}>
        {children}
      </Flex>
    );
  };

  const BolStatusFilter = () => {
    return (
      <>
        {Object.values(checkboxActive).map((item, index) => (
          <RenderFilterItem key={index}>
            {item.label}
            <StyledVscChromeClose className="text-xl" onClick={() => handleRemoveFilter(item.value)} />
          </RenderFilterItem>
        ))}
      </>
    );
  };

  const BolStatusFilterRender = () => {
    return (
      <>
        <Paragraph>Please, select the status(es) you`d like to filter by</Paragraph>
        <StyledCheckboxGroup options={checkboxOptions} value={checkedList} onChange={handlerCheckedListFilter} />
      </>
    );
  };

  const BolNumberFilter = () => {
    return (
      <>
        <RenderFilterItem>
          {reference}
          <StyledVscChromeClose
            className="text-xl"
            onClick={() => {
              setReference(null);
            }}
          />
        </RenderFilterItem>
      </>
    );
  };

  const BolNumberFilterRender = () => {
    return (
      <>
        <Paragraph>Filter by a Bill of Lading number or reference number</Paragraph>
        <Select
          className="w-full"
          placeholder="Select"
          options={referenceOption}
          onChange={handleReference}
          value={reference}
        />
      </>
    );
  };

  const CreationDateFilter = () => {
    return (
      <>
        <RenderFilterItem>
          <span style={{ color: baseColors.grayLight }}>
            {selectedDates && selectedDates[0]} {formatSeparator} {selectedDates && selectedDates[1]}
          </span>

          <StyledVscChromeClose
            className="text-xl"
            onClick={() => {
              setSelectedDates([]);
              setFormatSeparator(null);
            }}
          />
        </RenderFilterItem>
      </>
    );
  };

  const CreationDateFilterRender = () => {
    return (
      <>
        <Paragraph>Select creation/last modification date (date range) you`d like to filter by</Paragraph>
        <StyledRangePicker
          className="w-full"
          placeholder={['Select date', '']}
          separator={formatSeparator}
          format={dateFormat}
          value={selectedDates[0] && [dayjs(selectedDates[0], dateFormat), dayjs(selectedDates[1], dateFormat)]}
          onChange={handleDateChange}
        />
      </>
    );
  };

  const CarrierNameFilter = () => {
    return (
      <>
        <RenderFilterItem>
          {carrierName}
          <StyledVscChromeClose
            className="text-xl"
            onClick={() => {
              setCarrierName(null);
            }}
          />
        </RenderFilterItem>
      </>
    );
  };

  const CarrierNameFilterRender = () => {
    return (
      <>
        <Paragraph>Filter Bills of Lading by a carrier name</Paragraph>
        <Select
          className="w-full"
          placeholder="Select"
          options={carrierNameOption}
          onChange={handleCarrierName}
          value={carrierName}
        />
      </>
    );
  };

  const ConsigneeNameFilter = () => {
    return (
      <>
        <RenderFilterItem>
          {consigneeName}
          <StyledVscChromeClose
            className="text-xl"
            onClick={() => {
              setConsigneeName(null);
            }}
          />
        </RenderFilterItem>
      </>
    );
  };

  const ConsigneeNameFilterRender = () => {
    return (
      <>
        <Paragraph>Filter Bills of Lading by a consignee name</Paragraph>
        <Select
          className="w-full"
          placeholder="Select"
          options={consigneeNameOption}
          onChange={handleConsigneeName}
          value={consigneeName}
        />
      </>
    );
  };

  const NotifyPartyFilter = () => {
    return (
      <>
        <RenderFilterItem>
          {notifyParty}
          <StyledVscChromeClose
            className="text-xl"
            onClick={() => {
              setNotifyParty(null);
            }}
          />
        </RenderFilterItem>
      </>
    );
  };

  const NotifyPartyFilterRender = () => {
    return (
      <>
        <Paragraph>Filter Bills of Lading by a notify party</Paragraph>
        <Select
          className="w-full"
          placeholder="Select"
          options={notifyPartyOption}
          onChange={handleNotifyParty}
          value={notifyParty}
        />
      </>
    );
  };

  const LoadingPortFilter = () => {
    return (
      <>
        <RenderFilterItem>
          {loadingPort}
          <StyledVscChromeClose
            className="text-xl"
            onClick={() => {
              setLoadingPort(null);
            }}
          />
        </RenderFilterItem>
      </>
    );
  };

  const LoadingPortFilterRender = () => {
    return (
      <>
        <Paragraph>Filter Bills of Lading by loading port name</Paragraph>
        <Select
          className="w-full"
          placeholder="Select"
          options={loadingPortOption}
          onChange={handleLoadingPort}
          value={loadingPort}
        />
      </>
    );
  };

  const DischargingPortFilter = () => {
    return (
      <>
        <RenderFilterItem>
          {dischargingPort}
          <StyledVscChromeClose
            className="text-xl"
            onClick={() => {
              setDischargingPort(null);
            }}
          />
        </RenderFilterItem>
      </>
    );
  };

  const DischargingPortFilterRender = () => {
    return (
      <>
        <Paragraph>Filter Bills of Lading by discharging port name</Paragraph>
        <Select
          className="w-full"
          placeholder="Select"
          options={dischargingPortOption}
          onChange={handleDischargingPort}
          value={dischargingPort}
        />
      </>
    );
  };

  const TypeCargoFilter = () => {
    return (
      <>
        <RenderFilterItem>
          {typeCargo}
          <StyledVscChromeClose
            className="text-xl"
            onClick={() => {
              setTypeCargo(null);
            }}
          />
        </RenderFilterItem>
      </>
    );
  };

  const TypeCargoFilterRender = () => {
    return (
      <>
        <Paragraph>Filter Bills of Lading by a type of cargo</Paragraph>
        <Select
          className="w-full"
          placeholder="Select"
          options={typeCargoOption}
          onChange={handleTypeCargo}
          value={typeCargo}
        />
      </>
    );
  };

  const ModeTransportFilter = () => {
    return (
      <>
        <RenderFilterItem>
          {modeTransport}
          <StyledVscChromeClose
            className="text-xl"
            onClick={() => {
              setModeTransport(null);
            }}
          />
        </RenderFilterItem>
      </>
    );
  };

  const ModeTransportFilterRender = () => {
    return (
      <>
        <Paragraph>Filter Bills of Lading by a mode of transport</Paragraph>
        <Select
          className="w-full"
          placeholder="Select"
          options={modeTransportOption}
          onChange={handleModeTransport}
          defaultValue={"Vessel"}
          value={modeTransport}
          disabled
        />
      </>
    );
  };

  const ExportFilter = () => {
    return (
      <>
        <RenderFilterItem>
          {exportData}
          <StyledVscChromeClose
            className="text-xl"
            onClick={() => {
              setExport(null);
            }}
          />
        </RenderFilterItem>
      </>
    );
  };

  const ExportFilterRender = () => {
    return (
      <>
        <Paragraph>Filter Bills of Lading by a type of shipment</Paragraph>
        <Select
          className="w-full"
          placeholder="Select"
          options={exportOption}
          onChange={handleExport}
          value={exportData}
        />
      </>
    );
  };

  const renderMenuContainerItem = ({ id, label, icon, active }: RenderMenuContainerItemProps) => (
    <StyledMenuContainerItem
      className={active ? 'selected' : ''}
      onClick={() => handleClickFilter({ id, label, icon, active })}
    >
      <div className="icon">{icon}</div>
      <span>{label}</span>
    </StyledMenuContainerItem>
  );

  const handleCloseModal = () => {
    onClose();
  };

  const clearAllFilters = () => {
    setCheckedList([]);
    setCheckboxActive({});
    setSelectedDates([]);
    setFormatSeparator(null);
    setReference(null);
    setCarrierName(null);
    setConsigneeName(null);
    setNotifyParty(null);
    setLoadingPort(null);
    setDischargingPort(null);
    setTypeCargo(null);
    setModeTransport(null);
    setExport(null);
  };

  return (
    <>
      <Modal width={900} open={isOpen} onCancel={onClose} footer={null}>
        <div className="p-6">
          <Flex wrap="wrap">
            <div className="mt-16 overflow-auto" style={{ maxHeight: '55vh', width: '40%' }}>
              {filtersItem.map(({ id, label, icon, active }) => (
                <Fragment key={id}>
                  {renderMenuContainerItem({ id, label, icon, active })}
                  {id === 1 && <BolStatusFilter />}
                  {id === 2 && formatSeparator && <CreationDateFilter />}
                  {id === 3 && reference && <BolNumberFilter />}
                  {id === 4 && carrierName && <CarrierNameFilter />}
                  {id === 5 && consigneeName && <ConsigneeNameFilter />}
                  {id === 6 && notifyParty && <NotifyPartyFilter />}
                  {id === 7 && loadingPort && <LoadingPortFilter />}
                  {id === 8 && dischargingPort && <DischargingPortFilter />}
                  {id === 9 && typeCargo && <TypeCargoFilter />}
                  {id === 10 && modeTransport && <ModeTransportFilter />}
                  {id === 11 && exportData && <ExportFilter />}
                </Fragment>
              ))}
            </div>
            <div className="overflow-auto pl-16" style={{ maxHeight: '62vh', width: '60%' }}>
              <Title className="rubik-semi-bold mt-3 pb-5" level={4}>
                Filters
              </Title>
              {activeFilterId === 1 && <BolStatusFilterRender />}
              {activeFilterId === 2 && <CreationDateFilterRender />}
              {activeFilterId === 3 && <BolNumberFilterRender />}
              {activeFilterId === 4 && <CarrierNameFilterRender />}
              {activeFilterId === 5 && <ConsigneeNameFilterRender />}
              {activeFilterId === 6 && <NotifyPartyFilterRender />}
              {activeFilterId === 7 && <LoadingPortFilterRender />}
              {activeFilterId === 8 && <DischargingPortFilterRender />}
              {activeFilterId === 9 && <TypeCargoFilterRender />}
              {activeFilterId === 10 && <ModeTransportFilterRender />}
              {activeFilterId === 11 && <ExportFilterRender />}
            </div>
            <Flex gap="25px" className="mt-auto  w-full pt-5" justify="flex-end">
              <Button onClick={clearAllFilters}>Clear all filters</Button>
              <Button onClick={handleCloseModal} type="primary">
                Apply all filters
              </Button>
            </Flex>
          </Flex>
        </div>
      </Modal>
    </>
  );
}
