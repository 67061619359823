export const STUB_BILL_OF_LADING_ENTITY = {
  documentStatus: 'DRFT',
  descriptionOfConsignmentItems: '',
  incoterms: 'Some incoterms',
  billOfLadingNumber: '1111',
  masterBillOfLadingNumber: '2222',
  carrierParty: {
    partyName: 'Test_Carrier2',
    separatedAddress: {
      name: 'Test_Carrier2',
      street: 'Street',
      streetNumber: '10',
      floor: '',
      additionalInfo: '',
      postCode: '00000',
      city: 'City',
      stateRegion: '',
      country: 'Country',
    },
    separatedPartyContactDetails: [
      {
        name: 'Test_Carrier2',
        phone: '+41000000',
        fax: '',
        legalForm: 'LegalForm',
        email: 'test_carrier2@test.com',
      },
    ],
  },
  shipperBankParty: {
    partyName: 'Test_Bank2',
    separatedAddress: {
      name: 'Test_Bank2',
      street: 'Street',
      streetNumber: '10',
      floor: '',
      additionalInfo: '',
      postCode: '00000',
      city: 'City',
      stateRegion: '',
      country: 'Country',
    },
    separatedPartyContactDetails: [
      {
        name: 'Test_Bank2',
        phone: '+41000000',
        fax: '',
        legalForm: 'LegalForm',
        email: 'test_bank2@test.com',
      },
    ],
  },
  shipperParty: {
    partyName: 'Test_Shipper4',
    separatedAddress: {
      name: 'Test_Shipper4',
      street: 'Street',
      streetNumber: '10',
      floor: '',
      additionalInfo: '',
      postCode: '00000',
      city: 'City',
      stateRegion: '',
      country: 'Country',
    },
    separatedPartyContactDetails: [
      {
        name: 'Test_Shipper4',
        phone: '+41000000',
        fax: '',
        legalForm: 'LegalForm',
        email: 'test_shipper4@test.com',
      },
    ],
  },
  importerBankParty: {
    partyName: 'Test_Bank2',
    separatedAddress: {
      name: 'Test_Bank2',
      street: 'Street',
      streetNumber: '10',
      floor: '',
      additionalInfo: '',
      postCode: '00000',
      city: 'City',
      stateRegion: '',
      country: 'Country',
    },
    separatedPartyContactDetails: [
      {
        name: 'Test_Bank2',
        phone: '+41000000',
        fax: '',
        legalForm: 'LegalForm',
        email: 'test_bank2@test.com',
      },
    ],
  },
  importerParty: {
    partyName: 'Test_Buyer2',
    separatedAddress: {
      name: 'Test_Buyer2',
      street: 'Street',
      streetNumber: '10',
      floor: '',
      additionalInfo: '',
      postCode: '00000',
      city: 'City',
      stateRegion: '',
      country: 'Country',
    },
    separatedPartyContactDetails: [
      {
        name: 'Test_Buyer2',
        phone: '+41000000',
        fax: '',
        legalForm: 'LegalForm',
        email: 'test_buyer2@test.com',
      },
    ],
  },
  carrierUserId: '5740d803-3eb1-44eb-a3b1-3714ac821a8b',
  shipperUserId: '72c926e2-ffa8-4f16-a4fb-cb0d53f25fb8',
  importerUserId: 'adfcc595-ab74-4e0e-9712-20e471e71e19',
  importerBankUserId: '34015f14-3f6c-4be8-9517-a7c6b8ce6199',
  shipperBankUserId: '34015f14-3f6c-4be8-9517-a7c6b8ce6199',
  toOrder: false,
  notifyingParty: {
    partyName: 'Test_Buyer2',
    separatedAddress: {
      name: 'Test_Buyer2',
      street: 'Street',
      streetNumber: '10',
      floor: '',
      additionalInfo: '',
      postCode: '00000',
      city: 'City',
      stateRegion: '',
      country: 'Country',
    },
    separatedPartyContactDetails: [
      {
        name: 'Test_Buyer2',
        phone: '+41000000',
        fax: '',
        legalForm: 'LegalForm',
        email: 'test_buyer2@test.com',
      },
    ],
  },
  forDeliveryOfGoodsParty: {
    partyName: 'Test_Carrier2',
    separatedAddress: {
      name: 'Test_Carrier2',
      street: 'Street',
      streetNumber: '10',
      floor: '',
      additionalInfo: '',
      postCode: '00000',
      city: 'City',
      stateRegion: '',
      country: 'Country',
    },
    separatedPartyContactDetails: [
      {
        name: 'Test_Carrier2',
        phone: '+41000000',
        fax: '',
        legalForm: 'LegalForm',
        email: 'test_carrier2@test.com',
      },
    ],
  },
  numberOfRiderPages: 53,
  termsAndConditions: [],
  receiptTypeAtOrigin: 'CY',
  deliveryTypeAtDestination: 'CFS',
  cargoMovementTypeAtOrigin: 'BB',
  cargoMovementTypeAtDestination: 'BB',
  serviceContractReference: '43',
  transports: [
    {
      transportPlanStageCode: 'PRC',
      transportPlanStageSequenceNumber: '523423',
      plannedDepartureDate: '2024-04-17T15:31:30+03:00',
      plannedArrivalDate: '2024-04-25T15:31:36+03:00',
      modeOfTransport: 'RAIL',
      vesselName: '324',
      vesselIMONumber: '1234567',
      carrierImportVoyageNumber: '43223',
      universalImportVoyageReference: '12AWE',
      carrierExportVoyageNumber: '12AWE',
      universalExportVoyageReference: '15AWE',
      isUnderShippersResponsibility: false,
    },
  ],
  declaredValue: 52,
  declaredValueCurrency: '523',
  shipmentLocations: [],
  placeOfReceiptLocation: {
    locationName: 'Place of receipt Location',
    separatedAddress: {
      name: 'Place of receipt Location',
      street: 'Place of receipt Street',
      streetNumber: 'Place of receipt Street Number',
      floor: '',
      additionalInfo: '',
      postCode: 'VD4323',
      city: 'Place of receipt City',
      stateRegion: 'Place of receipt State',
      country: "Cote d'Ivoire",
    },
  },
  portOfLoadLocation: {
    locationName: 'Loading goods location',
    separatedAddress: {
      name: 'Loading goods location',
      street: 'Loading Street',
      streetNumber: 'Loading Street Number',
      floor: 'Loading Floor',
      additionalInfo: '',
      postCode: 'DFR1234',
      city: 'Loading City',
      stateRegion: 'State, Province, or Region',
      country: 'Chile',
    },
  },
  portOfDischargeLocation: {
    locationName: 'Discharge the goods location',
    separatedAddress: {
      name: 'Discharge the goods location',
      street: 'discharge the goods Street',
      streetNumber: 'discharge the goods Street Number',
      floor: '',
      additionalInfo: '',
      postCode: 'FK3425',
      city: 'discharge the goods City',
      stateRegion: 'discharge the goods State',
      country: 'Dominica',
    },
  },
  placeOfDeliveryLocation: {
    locationName: 'Place of delivery',
    separatedAddress: {
      name: 'Place of delivery',
      street: 'Place of delivery Street',
      streetNumber: 'Place of delivery Street Number',
      floor: 'Place of delivery Floor',
      additionalInfo: '',
      postCode: 'BKS2523',
      city: 'Place of delivery City',
      stateRegion: 'Place of delivery State',
      country: 'New Zealand',
    },
  },
  consignmentItems: [
    {
      weight: 39,
      weightUnit: 'KGM',
      volume: 12,
      volumeUnit: 'MTQ',
      description: 'Some description',
      hsCode: '2314123',
      numberOfPackages: 34,
      packageCode: '123',
      containerNumber: '4',
      packageNameOnBL: '43G2',
    },
  ],
  invoicePayableAt: {
    locationName: 'Invoice Place',
    separatedAddress: {
      name: 'Invoice Place',
      street: '',
      streetNumber: '',
      floor: '',
      additionalInfo: '',
      postCode: '',
      city: '',
      stateRegion: '',
      country: '',
    },
  },
  placeOfIssue: {
    locationName: 'Test_Carrier2',
    separatedAddress: {
      name: 'Test_Carrier2',
      street: 'Street',
      streetNumber: '10',
      floor: '',
      additionalInfo: '',
      postCode: '00000',
      city: 'City',
      stateRegion: '',
      country: 'Country',
    },
  },
  charges: [],
  attachments: [],
  signedByShipper: false,
};
