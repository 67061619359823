import React, { createContext, ReactElement, useContext, useState } from 'react';

export type BreadcrumbsContextType = {
  breadcrumbs: string;
  titleText: string;
  updateBreadcrumbs: (v: string | ReactElement) => void;
  updateTitleText: (v: string | ReactElement) => void;
  previousEblCallbackData: { callback: () => void };
  updatePreviousEblCallback: (fn: () => void) => void;
};

const BreadcrumbsContext = createContext<any>({});

export const BreadcrumbsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = useState<string>('Dashboard');
  const [titleText, setTitleText] = useState<string>('Welcome to dashboard');
  const [previousEblCallbackData, setOnUsePreviousEbl] = useState<{ callback: () => void }>({
    callback: () => {},
  });
  const updateBreadcrumbs = (newBreadcrumbs: string) => setBreadcrumbs(newBreadcrumbs);
  const updateTitleText = (newTitleText: string) => setTitleText(newTitleText);

  const updatePreviousEblCallback = (callback: () => void) => {
    setOnUsePreviousEbl({
      callback,
    });
  };

  return (
    <BreadcrumbsContext.Provider
      value={{
        breadcrumbs,
        titleText,
        updateBreadcrumbs,
        updateTitleText,
        previousEblCallbackData: previousEblCallbackData,
        updatePreviousEblCallback: updatePreviousEblCallback,
      }}
    >
      {children}
    </BreadcrumbsContext.Provider>
  );
};

export const useBreadcrumbs = (): BreadcrumbsContextType => {
  return useContext(BreadcrumbsContext);
};
