import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';

import AppRouter from './AppRouter';
import { GlobalNotificationWrapper } from './components';
import { BreadcrumbsProvider } from './components/BreadcrumbsContext';
import GlobalErrorHandler from './components/GlobalErrorHandler';
import { MessageCenterHandleComponent } from './components/MessageCenterHandleComponent';
import theme from './theme/haptik';

const queryClient = new QueryClient();

export default function App() {
  return (
    <BrowserRouter>
      <GlobalErrorHandler>
        <QueryClientProvider client={queryClient}>
          <ConfigProvider theme={theme}>
            <GlobalNotificationWrapper>
              <MessageCenterHandleComponent>
                <BreadcrumbsProvider>
                  <AppRouter />
                </BreadcrumbsProvider>
              </MessageCenterHandleComponent>
            </GlobalNotificationWrapper>
          </ConfigProvider>
        </QueryClientProvider>
      </GlobalErrorHandler>
    </BrowserRouter>
  );
}
