import { Select, SelectProps } from 'antd';

import { COUNTRIES } from '../utils/getCountries';

export const CountriesSelect = (props: SelectProps) => {
  return (
    <Select
      {...props}
      showSearch
      options={COUNTRIES}
      filterOption={(input, option) =>
        ((option?.label as string) || '')
          .trim()
          .toLowerCase()
          .includes((input || '').trim().toLowerCase())
      }
      optionRender={(option) => (
        <span>
          <i role="img" className={`fi fi-${option?.data?.code?.toLowerCase()} mr-4`} />
          {option.label}
        </span>
      )}
    />
  );
};
