import React, {ChangeEvent} from 'react';
import {Form, Input, Row, Col} from 'antd';
import {SeparatedAddress} from 'pages/BillsOfLading/APIs';

interface DetailsFormProps {
    details: SeparatedAddress;
    onChange: (name: string, value: string) => void;
}

const DetailsForm: React.FC<DetailsFormProps> = ({details, onChange}) => {
    const handleDetailsChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        onChange(name, value);
    };

    return (
        <Form layout="vertical">
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item label="Name">
                        <Input
                            name="name"
                            value={details.name}
                            onChange={handleDetailsChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Postal Code">
                        <Input
                            name="postCode"
                            value={details.postCode}
                            onChange={handleDetailsChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Street">
                        <Input
                            name="street"
                            value={details.street}
                            onChange={handleDetailsChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Street Number">
                        <Input
                            name="streetNumber"
                            value={details.streetNumber}
                            onChange={handleDetailsChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Floor">
                        <Input
                            name="floor"
                            value={details.floor}
                            onChange={handleDetailsChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Additional Information">
                        <Input
                            name="additionalInfo"
                            value={details.additionalInfo}
                            onChange={handleDetailsChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="City">
                        <Input
                            name="city"
                            value={details.city}
                            onChange={handleDetailsChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="State">
                        <Input
                            name="stateRegion"
                            value={details.stateRegion}
                            onChange={handleDetailsChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Country">
                        <Input
                            name="country"
                            value={details.country}
                            onChange={handleDetailsChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="E-Mail">
                        <Input
                            name="email"
                            value={details.email}
                            onChange={handleDetailsChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Telephone Number">
                        <Input
                            name="phoneNumber"
                            value={details.phoneNumber}
                            onChange={handleDetailsChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Fax Number">
                        <Input
                            name="faxNumber"
                            value={details.faxNumber}
                            onChange={handleDetailsChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Legal Entity">
                        <Input
                            name="legalForm"
                            value={details.legalForm}
                            onChange={handleDetailsChange}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default DetailsForm;
