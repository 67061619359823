import '/node_modules/flag-icons/css/flag-icons.min.css';

import * as i18nISOCountries from 'i18n-iso-countries';
import * as en from 'i18n-iso-countries/langs/en.json';
import { DefaultOptionType } from 'rc-select/lib/Select';

i18nISOCountries.registerLocale(en);

export function getCountries(): Pick<DefaultOptionType, 'label' | 'value' | 'code'>[] {
  return Object.entries(i18nISOCountries.getNames('en')).reduce((memo: any[], [value, label]) => {
    if (!['RU', 'BY'].includes(value)) {
      memo.push({ label, value: label, code: value });
    }
    return memo;
  }, []);
}

export const COUNTRIES = getCountries();
