export enum StepsNames {
  STEP_1 = 'choose-carrier',
  STEP_2 = 'choose-shipper',
  STEP_3 = 'choose-consignee',
  STEP_4 = 'transport-document-details',
  STEP_5 = 'transport-details',
  STEP_6 = 'consignment-items',
  STEP_7 = 'charges',
  STEP_8 = 'shipment-locations',
  STEP_9 = 'invoice-payable-at',
}
