import { FullToken, genComponentStyleHook, GenerateStyle } from 'antd/es/theme/internal';

import { baseColors } from './haptik';

const getButtonStyle: GenerateStyle<FullToken<'Button'>> = (token) => {
  const { componentCls } = token;
  return {
    [`${componentCls}.haptik`]: {
      [`&${componentCls}-text`]: {
        '&:hover': {
          backgroundColor: 'unset',
          color: baseColors.primaryActive,
        },
        '&:disabled': {
          color: baseColors.primary,
          opacity: 0.5,
        },
      },
    },
  };
};

export const genButtonStyle = genComponentStyleHook(['Button', 'haptik'], (token) => {
  return [getButtonStyle(token)];
});
