import type { UploadProps } from 'antd';
import { Button, Flex, Modal, Typography, Upload } from 'antd';
import { FiDownload } from 'react-icons/fi';

import { baseColors } from '../theme';

const { Title, Paragraph } = Typography;
const { Dragger } = Upload;

type Props = {
  isOpen: boolean;
  uploadProps: UploadProps;
  title: string;
  text: string;
  submitText?: string;
  onSave?: () => void;
  onClose: () => void;
};

export default function UploadModal({ isOpen, uploadProps, title, text, submitText, onSave, onClose }: Props) {

  const handleSave = ()=> {
    onSave?.();
    onClose();
  };

  return (
    <Modal width={600} open={isOpen} onCancel={onClose} footer={null}>
      <div className="px-12 pb-6">
        <Title className="rubik-semi-bold text-center mt-3" level={3}>
          {title}
        </Title>

        <Paragraph className="text-center my-10">{text}</Paragraph>

        <Dragger {...uploadProps} style={{ background: baseColors.draggerBg, borderColor: baseColors.primary }}>
          <Paragraph className="ant-upload-drag-icon">
            <FiDownload color={baseColors.primary} size={40} />
          </Paragraph>
          <Paragraph className="mulish-semi-bold text-lg" style={{ color: baseColors.primary }}>
            Click or drag file to this area to upload
          </Paragraph>
        </Dragger>

        <Flex gap="large" vertical>
          <Button className="w-full mt-14" type="primary" onClick={handleSave}>
            {submitText || 'Save documents'}
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </Flex>
      </div>
    </Modal>
  );
}
