import { Flex } from 'antd';
import { ReactElement } from 'react';

import Loader from './Loader';

export default function PageLoader(): ReactElement {
  return (
    <Flex className="h-full flex-col items-center justify-center">
      <div className="flex-none w-20 h-20 relative">
        <Loader isLoading={true} />
      </div>
    </Flex>
  );
}
