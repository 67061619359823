import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { DocumentParticipantDTO } from 'api/ebl';
import { SeparatedAddress } from '../APIs';
import { AddressPostalDto } from 'api/addresses_postal';
import { PartyDTO } from 'api/contacts';
import DocumentParticipantUtil from 'api/document_participent';

export type PartyData = {
    carrier: DocumentParticipantDTO;
    carrierPostal: SeparatedAddress;
    shipper: DocumentParticipantDTO;
    shipperPostal: SeparatedAddress;
    consignee: DocumentParticipantDTO;
    consigneePostal: SeparatedAddress;
    notifyParty: DocumentParticipantDTO;
    notifyPartyPostal: SeparatedAddress;
    forDeliveryOfGoodsParty: DocumentParticipantDTO;
    forDeliveryOfGoodsPartyPostal: SeparatedAddress;
};

export type UpdateByKeyFunction = (key: keyof PartyData, participant: DocumentParticipantDTO, addressPostal: AddressPostalDto) => void;
export type UpdateAddress = (key: keyof PartyData,  address: SeparatedAddress) => void;
export type UpdateParticipantByPartyFunction = (key: keyof PartyData, party: PartyDTO, role?:string) => void;
export type UpdateParticipantFunction = (key: keyof PartyData, participant: DocumentParticipantDTO) => void;




export type InitValuesFunction = (values: PartyData) => void;

export type BillsOfLadingPartiesContextType = {
    partyData: PartyData;
    updatePartyData: UpdateByKeyFunction;
    updateParticipant: UpdateParticipantFunction;
    updateParticipantByParty:UpdateParticipantByPartyFunction;
    updateAddress: UpdateAddress;
    initValues: InitValuesFunction;
};

const INIT_PARTY_DATA: PartyData = {
    carrier: {} as DocumentParticipantDTO,
    carrierPostal: {} as SeparatedAddress,
    shipper: {} as DocumentParticipantDTO,
    shipperPostal: {} as SeparatedAddress,
    consignee: {} as DocumentParticipantDTO,
    consigneePostal: {} as SeparatedAddress,
    notifyParty: {} as DocumentParticipantDTO,
    notifyPartyPostal: {} as SeparatedAddress,
    forDeliveryOfGoodsParty: {} as DocumentParticipantDTO,
    forDeliveryOfGoodsPartyPostal: {} as SeparatedAddress,
};

const INIT_CONTEXT_DATA: BillsOfLadingPartiesContextType = {
    partyData: INIT_PARTY_DATA,
    updatePartyData: () => {},
    updateParticipant: () => {},
    initValues: () => {},
    updateAddress: () => {},
    updateParticipantByParty: () => {},
};

export const BillsOfLadingPartiesContext = createContext<BillsOfLadingPartiesContextType>(INIT_CONTEXT_DATA);

export type BillsOfLadingPartiesProviderProps = {
    values?: PartyData;
    children: ReactNode;
};

export const BillsOfLadingPartiesProvider = ({ values, children }: BillsOfLadingPartiesProviderProps) => {
    const [partyData, setPartyData] = useState<PartyData>(INIT_PARTY_DATA);

    useEffect(() => {
        if (values) {
            initValues(values);
        }
    }, [values]);

    const updatePartyData: UpdateByKeyFunction = (key, participant, addressPostal) => {
        console.log("bin drin");
        setPartyData(prevData => ({
            ...prevData,
            [key]: participant,
            [`${key}Postal`]: addressPostal,
        }));
        console.log(`Updated ${key} data: `, participant, addressPostal);
    };


    
    const updateAddress: UpdateAddress = (key, address) => {
        setPartyData(prevData => ({
            ...prevData,
            [`${key}Postal`]: address,
        }));
    };


    const updateParticipant: UpdateParticipantFunction = (key, participant) => {
        setPartyData(prevData => ({
            ...prevData,
            [key]: participant,
        }));
    };

    const updateParticipantByParty: UpdateParticipantByPartyFunction = (key, party, role?) => {
        const participant: DocumentParticipantDTO = DocumentParticipantUtil.createDocumentParticipantDTO( party, role ? role : key);
        setPartyData(prevData => ({
            ...prevData,
            [key]: participant,
        }));
    };

    const initValues: InitValuesFunction = (values) => {
        setPartyData(values);
        console.log('Initialized party data: ', values);
    };


    return (
        <BillsOfLadingPartiesContext.Provider value={{partyData, updatePartyData, initValues, updateParticipant, updateParticipantByParty, updateAddress}}>
            {children}
        </BillsOfLadingPartiesContext.Provider>
    );
};

export const useBillsOfLadingParties = () => {
    const context = useContext(BillsOfLadingPartiesContext);
    if (!context) {
        throw new Error('useBillsOfLadingParties must be used within a BillsOfLadingPartiesProvider');
    }
    return context;
};
