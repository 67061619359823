import React, { useEffect, useState } from 'react';
import { Button, Card, Divider, Input, Space, Table, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';
import { apiClient } from 'api-client';
import { useBillsOfLadingParties } from '../BillOfLadingParties/BillsOfLadingPartiesStateContext';
import PartyService, { PartyDTO } from 'api/party';
import DocumentParticipantUtil, {ROLE_CONSIGNEE, ROLE_SHIPPER} from 'api/document_participent';
import AddressPostalService, { AddressPostalDto } from '../../../api/addresses_postal';
import DetailsForm from '../../../components/DetailsForm';
import { SeparatedAddress } from 'pages/BillsOfLading/APIs';
import { DocumentParticipantDTO } from "../../../api/ebl";
import { AiOutlineArrowRight } from 'react-icons/ai';

const { Title } = Typography;

const EndorseBillOfLadingPage = () => {
    const { billOfLadingId } = useParams();
    const navigate = useNavigate();
    const { updatePartyData, partyData, updateParticipant, updateParticipantByParty, updateAddress } = useBillsOfLadingParties();
    const [parties, setParties] = useState<PartyDTO[]>([]);
    const [selectedParty, setSelectedParty] = useState<PartyDTO>();
    const [postalAddresses, setPostalAddresses] = useState<AddressPostalDto[]>([]);
    const [details, setDetails] = useState<SeparatedAddress>({
        name: '',
        postCode: '',
        street: '',
        streetNumber: '',
        floor: '',
        additionalInfo: '',
        city: '',
        stateRegion: '',
        country: '',
        email: '',
        phoneNumber: '',
        faxNumber: '',
        legalForm: '',
    });

    useEffect(() => {
        PartyService.list(apiClient).then((res) => {
            setParties(res.data);
        });

        if (partyData.shipperPostal) {
            setDetails({
                name: partyData.consigneePostal.name || '',
                postCode: partyData.consigneePostal.postCode || '',
                street: partyData.consigneePostal.street || '',
                streetNumber: partyData.consigneePostal.streetNumber || '',
                floor: partyData.consigneePostal.floor || '',
                additionalInfo: partyData.consigneePostal.additionalInfo || '',
                city: partyData.consigneePostal.city || '',
                stateRegion: partyData.consigneePostal.stateRegion || '',
                country: partyData.consigneePostal.country || '',
                email: partyData.consigneePostal.email || '',
                phoneNumber: partyData.consigneePostal.phoneNumber || '',
                faxNumber: partyData.consigneePostal.faxNumber || '',
                legalForm: partyData.consigneePostal.legalForm || ''
            });
        }
    }, [partyData.shipperPostal]);

    const handleSearch = (value: string) => {
        PartyService.search(apiClient, value).then((res) => {
            setParties(res.data);
        });
    };

    const handleCompanySelect = (party: PartyDTO) => {
        setSelectedParty(party);
        const participant: DocumentParticipantDTO = DocumentParticipantUtil.createDocumentParticipantDTO(party, ROLE_CONSIGNEE);
        updateParticipant(ROLE_CONSIGNEE, participant);

        AddressPostalService.listEx(apiClient, party.companyId, party.id).then((res) => {
            setPostalAddresses(res.data);
        });
    };

    const handlePostalSelect = (entity: AddressPostalDto) => {
        const initialDetails: SeparatedAddress = {
            name: entity.name || '',
            postCode: entity.postalCode || '',
            street: entity.street || '',
            streetNumber: entity.streetNumber || '',
            floor: entity.floor || '',
            additionalInfo: entity.additionalInfo || '',
            city: entity.city || '',
            stateRegion: entity.state || '',
            country: entity.country || '',
            email: entity.email || '',
            phoneNumber: entity.phoneNumber || '',
            faxNumber: entity.faxNumber || '',
            legalForm: entity.legalForm || '',
        };
        setDetails(initialDetails);
        updateAddress(ROLE_SHIPPER, initialDetails);
    };

    const handleDetailsChange = (name: string, value: string) => {
        setDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
        updateAddress(ROLE_SHIPPER, {
            ...details,
            [name]: value,
        });
    };

    const handleSave = (entity: PartyDTO, address: SeparatedAddress) => {
        updateParticipantByParty(ROLE_CONSIGNEE, entity);
        updateAddress(ROLE_CONSIGNEE, address);
    };

    const columns = [
        {
            title: 'Company',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Action',
            key: 'action',
            render: (record: PartyDTO) => (
                <Button type="primary" onClick={() => handleCompanySelect(record)}>
                    →
                </Button>
            ),
        },
    ];

    const columnsPostal = [
        {
            title: 'Address',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Action',
            key: 'action',
            render: (record: AddressPostalDto) => (
                <Button type="primary" onClick={() => handlePostalSelect(record)}>
                    →
                </Button>
            ),
        },
    ];

    return (
        <>
            <Title level={3}>Endorse eBL</Title>
            <Divider />
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <Card>
                    <Title level={4}>Current Owner</Title>
                    <p><strong>Name:</strong> {partyData.shipperPostal?.name}</p>
                    <p>
                        <strong>Address:</strong> {partyData.shipperPostal?.street} {partyData.shipperPostal?.streetNumber}, {partyData.shipperPostal?.city}, {partyData.shipperPostal?.country}
                    </p>
                </Card>
                <div style={{ textAlign: 'center', fontSize: '24px', rotate:'90deg' }}>
                    <AiOutlineArrowRight />
                </div>
                <Space direction="horizontal" size="large" style={{ display: 'flex', alignItems: 'flex-start', width: '100%', gap: '24px' }}>
                    <Card title="Legal entity search" style={{ flex: 1 }}>
                        <Input.Search
                            placeholder="Search for a legal entity or contact"
                            enterButton="Search"
                            onSearch={handleSearch}
                        />
                    </Card>
                    <Card title="eBL Party as Endorsee" style={{ flex: 1 }}>
                        <Table
                            columns={columns}
                            dataSource={parties}
                            rowKey="id"
                            pagination={{ pageSize: 7 }}
                        />
                    </Card>
                    <Card title="Adress book" style={{ flex: 1 }}>
                        <Table
                            columns={columnsPostal}
                            dataSource={postalAddresses}
                            rowKey="id"
                            pagination={{ pageSize: 7 }}
                        />
                    </Card>
                </Space>
                <Card title="Address" style={{ width: '100%' }}>
                    <DetailsForm details={details} onChange={handleDetailsChange} />
                </Card>
                <Button type="primary" style={{ marginTop: '20px' }} onClick={() => handleSave(selectedParty!, details)}>
                    Endorse eBL
                </Button>
            </Space>
        </>
    );
};

export default EndorseBillOfLadingPage;
