import loadable from '@loadable/component';
import PageLoader from 'components/PageLoader';
import MainLayout from 'layouts/MainLayout/MainLayout';
import { Navigate, Route, Routes } from 'react-router-dom';

import { StepsNames } from './pages/BillsOfLading/BillOfLadingCreate/StepsNames';
import DocumentTypesPage from "./pages/DocumentTypes/DocumentTypesPage";
import EndorseEbl from "./pages/BillsOfLading/BillOfLadingEndorse/EndorseEbl";

const DashboardPage = loadable(() => import('./pages/Dashboard/DashboardPage'), { fallback: <PageLoader /> });
const MyProfilePage = loadable(() => import('./pages/MyProfile/MyProfilePage'), { fallback: <PageLoader /> });
const CompanyPage = loadable(() => import('./pages/Company/CompanyPage'), { fallback: <PageLoader /> });

const MessageCenterPage = loadable(() => import('./pages/MessageCenter/MessageCenterPage'), {
  fallback: <PageLoader />,
});
const BillsOfLadingPage = loadable(() => import('./pages/BillsOfLading/BillsOfLadingPage'), {
  fallback: <PageLoader />,
});
const BillOfLadingDetailsPage = loadable(
  () => import('./pages/BillsOfLading/BillOfLadingDetailsPage/BillOfLadingDetailsPage'),
  { fallback: <PageLoader /> },
);
const BillOfLadingCreatePage = loadable(
  () => import('./pages/BillsOfLading/BillOfLadingCreate/BillOfLadingCreatePage'),
  { fallback: <PageLoader /> },
);
const BillOfLadingEditPage = loadable(() => import('./pages/BillsOfLading/BillOfLadingCreate/BillOfLadingEditPage'), {
  fallback: <PageLoader />,
});
// export const TransportDocumentReceiversStep = loadable(
//   () => import('./pages/BillsOfLading/BillOfLadingCreate/Steps/TransportDocumentReceiversStep'),
//   { fallback: <PageLoader /> },
// );
export const TransportDocumentDetailsStep = loadable(
  () => import('./pages/BillsOfLading/BillOfLadingCreate/Steps/TransportDocumentDetailsStep'),
  { fallback: <PageLoader /> },
);
export const TransportDetailsStep = loadable(
  () => import('./pages/BillsOfLading/BillOfLadingCreate/Steps/TransportDetailsStep'),
  { fallback: <PageLoader /> },
);
export const ConsignmentItemsStep = loadable(
  () => import('./pages/BillsOfLading/BillOfLadingCreate/Steps/ConsignmentItemsStep/ConsignmentItemsStep'),
  { fallback: <PageLoader /> },
);
export const ChargeItemsStep = loadable(
  () => import('./pages/BillsOfLading/BillOfLadingCreate/Steps/ChargeItemsStep/ChargeItemsStep'),
  {
    fallback: <PageLoader />,
  },
);
export const ShipmentLocationItemsStep = loadable(
  () => import('./pages/BillsOfLading/BillOfLadingCreate/Steps/ShipmentLocationItemsStep/ShipmentLocationItemsStep'),
  { fallback: <PageLoader /> },
);
export const InvoicePayableStep = loadable(
  () => import('./pages/BillsOfLading/BillOfLadingCreate/Steps/InvoicePayableStep'),
  { fallback: <PageLoader /> },
);
export const CarrierSelectionStep = loadable(
    () => import('../src/pages/BillsOfLading/BillOfLadingParties/Steps/CarrierSelect/CarrierSelection'),
    { fallback: <PageLoader /> },
);
export const ShipperSelectionStep = loadable(
    () => import('../src/pages/BillsOfLading/BillOfLadingParties/Steps/ShipperSelect/ShipperSelection'),
    { fallback: <PageLoader /> },
);
export const ConsigneeSelectionStep = loadable(
    () => import('../src/pages/BillsOfLading/BillOfLadingParties/Steps/ConsigneeSelect/ConsigneeSelection'),
    { fallback: <PageLoader /> },
);



const VersionPage = loadable(() => import('./pages/Version/VersionPage'), { fallback: <PageLoader /> });
const NotFound404Page = loadable(() => import('./pages/NotFound404Page'));

export default function AppRouter() {
  return (
    <Routes>
      <Route path="/">
        <Route element={<MainLayout />}>
          <Route index element={<Navigate to="/dashboard" />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/message-center" element={<MessageCenterPage />} />
          <Route path="/my-profile" element={<MyProfilePage />} />
          <Route path="/choose-ebl" element={<DocumentTypesPage />} />
          <Route path="/company" element={<CompanyPage />} />
          <Route path="/bills-of-lading">
            <Route index element={<BillsOfLadingPage />} />
            //toDo: Put everything together
            {/*<Route path="choose-parties-carrier" element={<CarrierSelectionStep />} />*/}
            {/*<Route path="choose-parties-shipper" element={<ShipperSelectionStep />} />*/}
            {/*<Route path="choose-parties-consignee" element={<ConsigneeSelectionStep />} />*/}

            <Route path="create" element={<BillOfLadingCreatePage />}>
              <Route index element={<Navigate to={`./${StepsNames.STEP_1}`} />} />
              <Route path={StepsNames.STEP_1} element={<CarrierSelectionStep />} />
              <Route path={StepsNames.STEP_2} element={<ShipperSelectionStep />} />
              <Route path={StepsNames.STEP_3} element={<ConsigneeSelectionStep />} />
              <Route path={StepsNames.STEP_4} element={<TransportDocumentDetailsStep />} />
              <Route path={StepsNames.STEP_5} element={<TransportDetailsStep />} />
              <Route path={StepsNames.STEP_6} element={<ConsignmentItemsStep />} />
              <Route path={StepsNames.STEP_7} element={<ChargeItemsStep />} />
              <Route path={StepsNames.STEP_8} element={<ShipmentLocationItemsStep />} />
              <Route path={StepsNames.STEP_9} element={<InvoicePayableStep />} />
            </Route>
            <Route path="edit/:billOfLadingId/*" element={<BillOfLadingEditPage />}>
              <Route index element={<Navigate to={`./${StepsNames.STEP_1}`} />} />
              <Route path={StepsNames.STEP_1} element={<CarrierSelectionStep />} />
              <Route path={StepsNames.STEP_2} element={<ShipperSelectionStep />} />
              <Route path={StepsNames.STEP_3} element={<ConsigneeSelectionStep />} />
              <Route path={StepsNames.STEP_4} element={<TransportDocumentDetailsStep />} />
              <Route path={StepsNames.STEP_5} element={<TransportDetailsStep />} />
              <Route path={StepsNames.STEP_6} element={<ConsignmentItemsStep />} />
              <Route path={StepsNames.STEP_7} element={<ChargeItemsStep />} />
              <Route path={StepsNames.STEP_8} element={<ShipmentLocationItemsStep />} />
              <Route path={StepsNames.STEP_9} element={<InvoicePayableStep />} />
            </Route>
            <Route path=":id" element={<BillOfLadingDetailsPage />} />
            <Route path=":id/endorsement" element={<EndorseEbl />} />
          </Route>
          <Route path="/version" element={<VersionPage />} />
        </Route>

        <Route path="*" element={<NotFound404Page />} />
      </Route>
    </Routes>
  );
}
