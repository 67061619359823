import isObject from 'lodash-es/isObject';
import { ReactElement, useContext, useMemo } from 'react';
import { AiFillInfoCircle } from 'react-icons/ai';
import { LuPenLine } from 'react-icons/lu';
import styled from 'styled-components';

import { baseColors } from '../../../theme';
import { MenuStep } from './BillOfLadingEditPage';
import { BillOfLadingStateContext } from './BillOfLadingStateContext';
import { StepsNames } from './StepsNames';

export type MenuComponentItemType = {
  active?: boolean;
  icon: ReactElement;
  label: string;
  value: StepsNames;
  error?: boolean;
  isAmendment?: boolean;
};

export type MenuComponentItemProps = MenuComponentItemType & {
  onChangeSelect?: (item: MenuComponentItemProps) => void;
};

export type MenuComponentProps = {
  items: MenuComponentItemType[];
  amendmentSections?: MenuStep;
  value?: MenuComponentItemType | string;
  onChange?: (item: MenuComponentItemType) => void;
};

export const StyledMenuContainerItem = styled('div')`
  display: flex;
  position: relative;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 52px;
  padding-left: 5px;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  &:hover .icon,
  &:active .icon,
  &.selected .icon {
    color: ${baseColors.primary};
  }

  &:hover .pen,
  &:active .pen,
  &.selected .pen {
    color: ${baseColors.primary};
  }

  &.selected,
  &:active,
  &:hover .icon,
  &:active .icon,
  &.selected .icon {
    background-color: ${baseColors.option};
  }

  & .invalid-step {
    color: ${baseColors.error};
    position: absolute;
    top: 0;
    left: 40px;
  }

  & .icon {
    padding: 16px;
    background-color: ${baseColors.iconBg};
    color: ${baseColors.icon};
    font-size: 20px;
    line-height: 0;
  }

  &,
  & .icon {
    border-radius: 8px;
    transition: all 0.2s ease-in-out;
  }

  @media screen and (max-height: 1000px) {
    & {
      height: 45px;
    }

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    & .icon {
      padding: 13px;
      font-size: 18px;
    }

    & > span {
    }
  }
`;

export const StyledMenuContainer = styled('div')`
  width: 100%;
  height: fit-content;
`;

export const MenuComponentItem = (props: MenuComponentItemProps) => {
  const { invalidSteps, createEblInitiated } = useContext(BillOfLadingStateContext);

  const isError = useMemo(() => {
    return createEblInitiated && invalidSteps.includes(props.value);
  }, [props.value, createEblInitiated, invalidSteps]);

  const classes = useMemo(() => {
    return props.active ? 'selected' : '';
  }, [props.active]);

  const handleOnClick = () => {
    props.onChangeSelect?.({
      ...props,
    });
  };

  return (
    <StyledMenuContainerItem className={classes} onClick={handleOnClick}>
      {isError && (
        <div className="invalid-step">
          <AiFillInfoCircle />
        </div>
      )}
      <div className="icon">{props.icon}</div>
      <span className="flex align-middle gap-3">{props.label}</span>
      {props.isAmendment && <LuPenLine className="ml-auto mr-4 pen" size="16" />}
    </StyledMenuContainerItem>
  );
};

export const MenuComponent = ({ items, amendmentSections, value, onChange }: MenuComponentProps) => {
  const selectedItemValue = useMemo(() => {
    return isObject(value) ? value.value : value;
  }, [value]);

  return (
    <StyledMenuContainer>
      {items.map(({ icon, label, value }) => (
        <MenuComponentItem
          key={value}
          active={value === selectedItemValue}
          icon={icon}
          label={label}
          value={value}
          isAmendment={amendmentSections && Object.keys(amendmentSections).includes(value)}
          onChangeSelect={onChange}
        />
      ))}
    </StyledMenuContainer>
  );
};
