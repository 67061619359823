import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { NotificationEntity } from '../models';
import { NotificationsInfoModal } from '../pages/MessageCenter/NotificationsInfoModal';
import { UnreadNotificationInfo, WS } from '../services/NotificationConnection';
import { GlobalNotificationsContext } from './GlobalNotifications';

export type MessageCenterContextProps = {
  unreadNotifications: number;
  notifications: NotificationEntity[];
};

const INITIAL_VALUE: MessageCenterContextProps = {
  unreadNotifications: 0,
  notifications: [],
};

export const MessageCenterContext = createContext<MessageCenterContextProps>(INITIAL_VALUE);

export const MessageCenterHandleComponent = ({ children }: { children: ReactNode }) => {
  const [unreadNotifications, setUnreadNotifications] = useState<number>(0);
  const [notifications, setNotifications] = useState<NotificationEntity[]>([]);
  const { notificationAPI } = useContext(GlobalNotificationsContext);
  const [unreadInfo, setUnreadInfo] = useState<UnreadNotificationInfo | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const needToShowModal = useRef(true);

  useEffect(() => {
    WS.initiate();

    const messageReceiveSubscription = WS.subscribe(handleMessageReceive);
    const infoSubscription = WS.infoSubscribe(handleUnreadInfoUpdate);

    return () => {
      messageReceiveSubscription.unsubscribe();
      infoSubscription.unsubscribe();
      WS.close();
    };
  }, []);

  const handleMessageReceive = (message: NotificationEntity) => {
    notificationAPI?.info({
      message: 'New message',
      description: <strong>{message.title}</strong>,
    });
  };

  const handleUnreadInfoUpdate = (info: UnreadNotificationInfo) => {
    if (needToShowModal.current) {
      needToShowModal.current = false;
      if (!location.pathname.includes('message-center') && info.unread > 0) {
        setUnreadInfo(info);
      }
    }

    setUnreadNotifications(info.unread);
    setNotifications(info.notifications);
  };

  const handleModalClose = () => {
    setUnreadInfo(null);
  };

  const handleAccept = () => {
    handleModalClose();
    navigate('/message-center');
  };

  return (
    <MessageCenterContext.Provider
      value={{
        unreadNotifications,
        notifications,
      }}
    >
      {children}
      {unreadInfo && (
        <NotificationsInfoModal
          open={!!unreadInfo}
          unreadInfo={unreadInfo}
          onAccept={handleAccept}
          onClose={handleModalClose}
        />
      )}
    </MessageCenterContext.Provider>
  );
};
