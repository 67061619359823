import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import UserService from './services/UserService';

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);

  const renderApp = () =>
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
    );

  UserService.initKeycloak(renderApp);
}
