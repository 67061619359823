import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';

import { baseColors } from '../../theme';
import MainHeader from './MainHeader';
import MainSidebar from './MainSidebar';

const { Content } = Layout;

export default function MainLayout() {
  return (
    <Layout className="min-h-screen" style={{ minWidth: '980px' }}>
      <MainSidebar />
      <Layout>
        <MainHeader />
        <Content className="p-10 bg-white main-content-scroll" style={{ background: baseColors.bgContent }}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}
