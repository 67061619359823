import { FullToken, genComponentStyleHook, GenerateStyle } from 'antd/es/theme/internal';

const ModalStyle: GenerateStyle<FullToken<'Modal'>> = (token) => {
  const { componentCls } = token;

  return {
    [`${componentCls}`]: {
      [`${componentCls}-content`]: {
        padding: '0',
        paddingTop: '20px',
      },
    },
  };
};

export const genModalStyle = genComponentStyleHook(['Modal', 'notification-info'], (token) => [ModalStyle(token)]);
