import { Input, InputProps, Select, SelectProps } from 'antd';
import { ChangeEvent } from 'react';

export type CombineInputValue<InputValue extends string = string, TypeValue extends string = string> = {
  input: InputValue;
  type: TypeValue;
};

export type CombineInputProps<InputValue extends string = string, TypeValue extends string = string> = {
  value: CombineInputValue<InputValue, TypeValue>;
  options: SelectProps['options'];
  onChange?: (value: CombineInputValue<InputValue, TypeValue>) => void;
} & Omit<InputProps, 'value' | 'onChange' | 'addonAfter'>;

export const CombineInput = ({ options, value, onChange, ...rest }: CombineInputProps) => {
  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.({
      input: e.currentTarget.value,
      type: value?.type ?? (options?.[0].value as any),
    });
  };

  const handleChangeSelect = (type: any) => {
    onChange?.({
      input: value?.input,
      type,
    });
  };

  return (
    <Input
      {...rest}
      value={value?.input}
      addonAfter={
        <Select defaultValue={options?.[0].value} value={value?.type} options={options} onChange={handleChangeSelect} />
      }
      onChange={handleChangeInput}
    />
  );
};
