import { Button, ConfigProvider, Flex, Modal, ModalProps } from 'antd';
import { useContext } from 'react';
import { IoIosNotificationsOutline } from 'react-icons/io';
import styled from 'styled-components';

import { UnreadNotificationInfo } from '../../services/NotificationConnection';
import { baseColors } from '../../theme';
import { genModalStyle } from './theme';

export type NotificationsInfoModalProps = Pick<ModalProps, 'open'> & {
  unreadInfo: UnreadNotificationInfo;
  onAccept?: () => void;
  onClose?: ModalProps['onCancel'];
};

const Icon = styled(IoIosNotificationsOutline)`
  font-size: 3.5rem;
  color: ${baseColors.primary};
`;

const Title = styled('h2')`
  margin-bottom: 10px;
  font-size: 1.786rem;
  font-weight: bold;
  color: ${baseColors.text};
`;

const Subtitle = styled('span')`
  color: ${baseColors.text};
`;

export function NotificationsInfoModal({
  open,
  unreadInfo,
  onClose,
  onAccept,
}: NotificationsInfoModalProps): JSX.Element {
  const { getPrefixCls } = useContext(ConfigProvider.ConfigContext);
  genModalStyle(getPrefixCls('modal'));

  return (
    <Modal className="notification-info" centered open={open} onCancel={onClose} footer={false}>
      <Flex vertical justify="center" align="center">
        <Icon />
        <Title>Dear user</Title>
        <Subtitle>
          You have {unreadInfo.unread} unread notification{unreadInfo.unread > 1 ? 's' : ''}
        </Subtitle>
      </Flex>
      <Flex justify="center" align="center" className="px-[30px] py-7 mt-[30px]">
        <Button type="primary" className="w-full" onClick={onAccept}>
          Go to messages
        </Button>
      </Flex>
    </Modal>
  );
}
