import { Layout, Menu, MenuProps } from 'antd';
import { useContext, useMemo, useState } from 'react';
import { AiFillFolder, AiFillHome, AiFillMessage, AiFillPieChart } from 'react-icons/ai';
import { RiLogoutCircleRFill } from 'react-icons/ri';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { MessageCenterContext } from '../../components/MessageCenterHandleComponent';
import UserService from '../../services/UserService';
import { baseColors } from '../../theme';
import { getItemByKey, setItemByKey } from '../../utils/webStorage';
import logo from './images/logo.svg';
import logoCollapsed from './images/logo-collapsed.svg';

const { Sider } = Layout;

const styles = {
  sider: {
    width: '310px',
    overflow: 'hidden',
    zIndex: 2,
    boxShadow: baseColors.boxShadowPrimary,
  },
};

const StyledMenu = styled(Menu)`
  & {
    display: flex;
    flex-direction: column;
    height: 93%;
    li:last-child {
      margin-top: auto;
    }
  }
  &.ant-menu-vertical {
    .ant-menu-item {
      background: transparent !important;
      text-align: center;
      svg {
        font-size: 1.5rem;
      }
    }
  }
  .ant-menu-item {
    svg {
      fill: ${baseColors.icon};
    }
    &-selected,
    &-active {
      overflow: visible;

      svg {
        fill: ${baseColors.primary};
      }
    }

    &-selected {
      &:before {
        content: '';
        width: 10px;
        height: 100%;
        border-radius: 10px;
        background: ${baseColors.primary};
        left: -20px;
        top: 0;
        position: absolute;
      }
    }
  }
`;

const StyledLogo = styled.img`
  transition: all 0.2s ease-in-out;
  &.collapsed {
    margin-left: -10px;
  }
`;

const StyledSider = styled(Sider)`
  &:hover {
    .ant-layout-sider-trigger {
      opacity: 1;
    }
  }

  .ant-layout-sider-trigger {
    height: 0;
    bottom: auto;
    top: 0;
    opacity: 0;
    transition: all 0.1s linear;
    &:hover {
      opacity: 1;
    }

    .anticon {
      justify-content: center;
      position: absolute;
      right: -13px;
      top: 80px;
      background-color: ${baseColors.white};
      border-radius: 50%;
      padding: 8px;
      font-size: 0.8rem;
      box-shadow: ${baseColors.boxShadowPrimary};
    }
  }
`;

const StyledUnreadNotificationContainer = styled.div`
  position: absolute;
  top: 7px;
  left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: ${baseColors.primary};
  font-size: 12px;
  scale: 0.7;
  color: white;
  transition: all 0.2s ease-in-out;

  &.collapsed {
    top: 0;
    left: 0;
    scale: 0.9;
  }
`;

function MessageBudge({ className }: Partial<Pick<HTMLSpanElement, 'className'>>) {
  const { unreadNotifications } = useContext(MessageCenterContext);

  return (
    <span className={className}>
      <AiFillMessage />
      {!!unreadNotifications && (
        <StyledUnreadNotificationContainer>{unreadNotifications}</StyledUnreadNotificationContainer>
      )}
    </span>
  );
}

const navigation = [
  { label: 'Dashboard', key: '/dashboard', icon: <AiFillPieChart /> },
  { label: 'Bills of Lading', key: '/bills-of-lading', icon: <AiFillFolder /> },
  { label: 'Message center', key: '/message-center', icon: <MessageBudge /> },
  { label: 'My profile', key: '/my-profile', icon: <AiFillHome /> },
  { label: 'My Company', key: '/company', icon: <AiFillHome /> },
  { label: 'Log out', key: 'logout', icon: <RiLogoutCircleRFill /> },
];

export default function MainSidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const activeRoute = useMemo(() => (location.pathname || '').split('/')?.[1] || 'dashboard', [location.pathname]);
  const [current, setCurrent] = useState(`/${activeRoute}`);

  const onClick: MenuProps['onClick'] = ({ key }) => {
    if (key === 'logout') {
      UserService.doLogout();
    } else {
      setCurrent(key);
      navigate(key);
    }
  };

  const isCollapsed = getItemByKey('sidebarCollapsed');
  const [collapsed, setCollapsed] = useState(isCollapsed || false);
  const toggleSidebar = (isCollapsed: boolean) => {
    setCollapsed(isCollapsed);
    setItemByKey('sidebarCollapsed', isCollapsed);
  };

  return (
    <StyledSider
      width={styles.sider.width}
      collapsible
      collapsed={collapsed}
      onCollapse={toggleSidebar}
      style={styles.sider}
    >
      <div className="p-6 h-full">
        <StyledLogo
          src={collapsed ? logoCollapsed : logo}
          alt="Logo"
          className={`mb-14 ${collapsed ? 'collapsed' : ''}`}
        />
        <StyledMenu mode="inline" onClick={onClick} selectedKeys={[current]} items={navigation} />
      </div>
    </StyledSider>
  );
}
