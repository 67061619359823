import { Button, Flex, Layout, Typography } from 'antd';
import { STUB_BILL_OF_LADING_ENTITY } from 'pages/BillsOfLading/BillOfLadingCreate/StubBillOfLadingEntity';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { useBreadcrumbs } from '../../components/BreadcrumbsContext';
import UserService from '../../services/UserService';
import { baseColors } from '../../theme';
import { getItemByKey, setItemByKey } from '../../utils/webStorage';
import HeaderActions from './HeaderActions';


const { Title } = Typography;
const { Header } = Layout;

const StyledHeader = styled(Header)`
  box-shadow: ${baseColors.boxShadowSecondary};
  background: ${baseColors.white};
  position: relative;
  
  padding-top: 7rem;
  line-height: 1;
`;
// height: 15rem;
export default function MainHeader() {
  const { breadcrumbs, titleText, previousEblCallbackData } = useBreadcrumbs();
  const location = useLocation();
  const isCarrier = UserService.isCarrierKC();
  const showHeaderActions = isCarrier && ['/dashboard', '/bills-of-lading'].includes(location.pathname);
  const previousEblExists = !!getItemByKey('previous-eBL');
  const isBillOfLadingCreationPage = useMemo(
    () => location.pathname.includes('bills-of-lading/create') && previousEblExists,
    [location.pathname],
  );

  useEffect(() => {
    if (!previousEblExists) {
      setItemByKey('previous-eBL', STUB_BILL_OF_LADING_ENTITY);
    }
  }, [previousEblExists]);

  return (
    <StyledHeader className="px-10 py-5 flex flex-col justify-end">


      <Flex className="items-center">
        
        <Title level={2} className="rubik-semi-bold mr-auto">
          {titleText}
        </Title>
        
        {showHeaderActions && <HeaderActions />}
        {isBillOfLadingCreationPage && (
          <Button className="mr-4" type="primary" onClick={previousEblCallbackData.callback}>
            Use previous eBL
          </Button>
        )}
      </Flex>
      <Flex className="items-center">

<div>{breadcrumbs}</div>
</Flex>      
    </StyledHeader>
  );
}
