import { Spin } from 'antd';
import { ReactElement } from 'react';

export default function Loader({ isLoading = false }: { isLoading?: boolean }): ReactElement | null {
  return isLoading ? (
    <Spin tip="Loading" size="large">
      <div className="content" />
    </Spin>
  ) : null;
}
