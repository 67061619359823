import axios, { AxiosError, AxiosInstance } from 'axios';
import { isEmpty } from 'lodash-es';

import UserService from './services/UserService';

export const BASE_API_URL = 'http://localhost:3000';
export const BASE_WEB_SOCKET_URL = 'wss://localhost:3000';

/*
const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    console.error(`[request error] [${JSON.stringify(error)}]`);
    //return Promise.reject(error);
    
}

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
    console.error(`[response error] [${JSON.stringify(error)}]`);
    //return Promise.reject(error);
}

export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
    axiosInstance.interceptors.request.use(undefined, onRequestError);
    axiosInstance.interceptors.response.use(undefined, onResponseError);
    return axiosInstance;
}
*/
export const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_API_URL || BASE_API_URL}`,
});

apiClient.interceptors.request.use((req) => {
  const token = UserService.getToken();

  if (!isEmpty(token)) {
    req.headers.set('Authorization', `Bearer ${token}`);
  }

  return req;
}


);

//setupInterceptorsTo(apiClient);